<template>
  <div id="app">
    <LoginModal
      :showLoginModal="showLoginModal"
      :showActiveTab="showActiveTab"
      :inviteCode="inviteCode"
      @setShowLoginModal="setShowLoginModal"
      @setLogin="setLogin"
    ></LoginModal>
    <VIPModal
      :showVIPModal="showVIPModal"
      @setShowVIPModal="setShowVIPModal"
    ></VIPModal>
    <WHeader
      :login="login"
      @show="showNav = true"
      @setShowLoginModal="setShowLoginModal"
      @setShowVIPModal="setShowVIPModal"
      @setLogin="setLogin"
      v-if="!$route.meta.isHide && $route.name !== 'play'"
    ></WHeader>
    <WNav
      v-show="showNav"
      :login="login"
      @setShow="setShowNav"
      @setShowLoginModal="setShowLoginModal"
      @setShowVIPModal="setShowVIPModal"
    ></WNav>
    <div :class="['app-content', isIframe ? 'app-content-pad-0' : '']">
      <router-view />
    </div>
    <WFooter v-if="!$route.meta.isHide && $route.name !== 'play'"></WFooter>
    <BottomNav v-if="!$route.meta.isHide && $route.name !== 'play'"
               @setShowLoginModal="setShowLoginModal"
               @setShow="setShowNav"></BottomNav>
  </div>
</template>

<script>
import WHeader from '@/components/WHeader.vue'
import WNav from '@/components/WNav.vue'
import WFooter from '@/components/WFooter.vue'
import BottomNav from '@/components/BottomNav.vue'
import LoginModal from '@/components/LoginModal.vue'
import VIPModal from '@/components/VIPModal.vue'
import { mapState, mapMutations } from 'vuex'
import cookies from "@/JS/util.cookies";

export default {
  components: {
    WHeader,
    WNav,
    WFooter,
    BottomNav,
    LoginModal,
    VIPModal,
  },
  data() {
    return {
      showNav: false,
      showLoginModal: false,
      showVIPModal: false,
      login: false,
      showActiveTab: 'Login',
      isIframe: false,
      inviteCode: '',
      isMobile: false
    }
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route: {
      handler: function(val, oldVal){
        if (val.path === '/pay' || val.path === '/play') {
          this.$nextTick(() => {
            this.$set(this, 'isIframe', true)
          })
        } else {
          this.$set(this, 'isIframe', false)
        }
      },
      // 深度观察监听
      deep: true
    },
  },
  computed: {
    ...mapState({
      uid: state => state.uid,
    }),
  },
  created() {
    let shareId = this.util.getUrlParams('v');
    console.log('login', shareId);
    if (shareId) {
      cookies.set('shareId', shareId);
      this.inviteCode = shareId;
    } else {
      cookies.remove('shareId');
    }
    this.watchRouter();
  },
  methods: {
    ...mapMutations(['setUserBalance']),
    handleResize() {
      const isMobile = window.innerWidth < 1028
      this.isMobile = isMobile;
    },
    watchRouter() {
      const { uid } = this;
      if (!uid) return;
      this.$api.getVipUserInfo({id: uid})
      .then(res => {
        if (res.success === true) {
          this.setUserBalance(res.result.balance.balance.toFixed(2));
        }
      }).catch(err => {
        this.$message.error('Erro do sistema.Tente novamente mais tarde.');
      })
    },
    setShowNav(value) {
      this.showNav = value
    },
    setShowLoginModal(value, tab) {
      this.showLoginModal = value
      if (tab) this.showActiveTab = tab
    },
    setShowVIPModal(value) {
      this.showVIPModal = value
    },
    setLogin(value) {
      this.login = value
    },
    mounted() {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

:root {
  --the: #10171d;
  --theme: 16, 23, 29;
  --theme-green: #2bc69d;
  --theme-200: #24262b;
  --theme-300: #1c1e22;
  --theme-400: #98a7b5;
  --theme-500: #31363d;
  --theme-600: #22474f;
  --theme-900: #17181b;
  --theme-1000: rgba(49, 52, 60, 0.4);
  --theme-1100: #94aeb4;
  --theme-1200: #67707b;
  --theme-1300: rgba(31, 233, 136, 0.25);
  --theme-1400: #b8cbdc;
  --theme-1500: rgba(184, 203, 220, 0.6);
  --theme-1600: #19232a;
  --theme-1900: #2b373f;
  --theme-2000: #35444e;
  --theme-2100: #19242d;
  --theme-2300: #151f27;
  --theme-2400: #61ddbc;
  --theme-2500: #434a55;
  --theme-2600: #1e232a;
  --theme-2700: rgba(49, 52, 60, 0.4);
  --theme-2800: #25272c;
  --theme-2900: #151c26;
  --primary-color: #3bc117;
  --theme-orange: #ea6518;
  --theme-100: rgba(var(--theme), 0.5);
  --theme-700: rgba(var(--theme), 0.5);
  --theme-800: rgba(var(--theme), 0.25);
  --theme-1700: rgb(var(--theme));
  --theme-1800: rgba(var(--theme), 0.3);
  --theme-2200: var(--theme);
  --theme-header: radial-gradient(
    100% 200% at 0% 0%,
    var(--theme-2000),
    var(--theme-2100)
  );
  --font-size-xl: 20px;
  --title-color: #fff;
  --text-color: var(--theme-400);
  --sub-text-color: #656e78;
  --placeholder-color: #4d565e;
  --background-color: var(--theme-200);
  --standard-color: var(--theme-300);
  --shadow-color: #111415;
  --page-shadow: linear-gradient(90deg, #111415, rgba(17, 20, 21, 0));
  --autofill-color: #f5f6f7;
  --yellow-color: #ffbf39;
  --border-radius: 0.125rem;
  --scrollbar-size: 8px;
  --blue-100: #cce5ff;
  --blue-200: #8fc5ff;
  --blue-300: #57a8ff;
  --blue-400: var(--theme-green);
  --blue-real-500: var(--theme-green);
  --blue-600: #0059b8;
  --blue-700: #00438a;
  --blue-800: rgba(59, 193, 23, 0.4);
  --blue-900: #011932;
  --red-100: #ffccd5;
  --red-200: #ff8fa2;
  --red-300: #ff5773;
  --red-400: #ff1f44;
  --red-400-0-2: rgba(255, 31, 68, 0.2);
  --red-500: #fe2247;
  --red-600: #b8001f;
  --red-600-0-2: rgba(184, 0, 31, 0.2);
  --red-700: #8a0017;
  --red-800: #4d000d;
  --red-900: #320109;
  --purple-100: #dcccff;
  --purple-200: #be8fff;
  --purple-300: #b061ff;
  --purple-400: #962eff;
  --purple-500: #9000ff;
  --purple-600: #7100c7;
  --purple-700: #590099;
  --purple-800: #2d004d;
  --purple-900: #1e0132;
  --green-100: #cfc;
  --green-200: #8fff8f;
  --green-300: #57ff57;
  --green-400: #1fff20;
  --green-500: #00e701;
  --green-real-500: #00e701;
  --green-600: #00b801;
  --green-700: #008a01;
  --green-800: #004d00;
  --green-900: #013e01;
  --yellow-100: #fff2dd;
  --yellow-200: #ffdeaa;
  --yellow-300: #ffcc7b;
  --yellow-400: #ffb949;
  --yellow-500: #ff9d00;
  --yellow-600: #cd7f01;
  --yellow-700: #9c6000;
  --yellow-800: #633d00;
  --yellow-900: #301d00;
  --grey-100: #adadc2;
  --grey-200: #adadc2;
  --grey-300: #557086;
  --grey-350: #3d5564;
  --grey-400: var(--theme-900);
  --grey-500: var(--theme-800);
  --grey-600: var(--theme-2700);
  --grey-700: var(--theme-800);
  --grey-800: #071d2a;
  --grey-900: #071824;
  --grey-888: #888;
  --font-size-xxs: 0.5rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --font-size-8xl: 6rem;
  --font-size-10xl: 8rem;
  --font-sizeEm-xs: 0.75em;
  --font-sizeEm-sm: 0.875em;
  --font-sizeEm-base: 1em;
  --font-sizeEm-lg: 1.125em;
  --font-sizeEm-xl: 1.25em;
  --font-sizeEm-2xl: 1.5em;
  --font-sizeEm-3xl: 1.875em;
  --font-sizeEm-4xl: 2.25em;
  --font-sizeEm-5xl: 3em;
  --font-sizeEm-6xl: 4em;
  --font-sizeEm-8xl: 6em;
  --font-sizeEm-10xl: 8em;

  --input-color: var(--white);
  --input-background: var(--grey-700);
  --input-border: var(--grey-400);
  --input-placeholder: #566671;
  --input-hover-color: var(--white);
  --input-hover-border: var(--grey-300);
  --input-transition: var(--transition-medium);
  --input-font-weight: 600;
  --input-letter-spacing: 0;
  --input-border-radius: var(--border-radius-0-25);
  --input-border-width: 2px;
  --input-box-shadow: var(--shadows-sm);
  --input-button-divider: var(--grey-600);
}

@font-face {
  font-family: 'zonapro';
  // src: url('./assets/zonapro-regular-webfont.woff2');
  src: url('./assets/zonapro-bold-webfont.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'zonapro-thin';
  src: url('./assets/zonapro-regular-webfont.woff2');
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}
// @media screen and (min-width: 621px) {
//   ::-webkit-scrollbar-button {
//     display: none;
//   }
//   ::-webkit-scrollbar {
//     width: 5px;
//     height: 5px;
//     background-color: initial;
//     border-radius: var(--border-radius);
//   }
//   ::-webkit-scrollbar-thumb {
//     background-color: var(--shadow-color);
//     -webkit-transition: all 0.5s;
//     transition: all 0.5s;
//     border-radius: var(--border-radius);
//   }
//   ::-webkit-scrollbar-track {
//     background-color: val(--the); /* 滚动条轨道颜色 */
//   }
// }

body {
  height: 100%;
  font-family: zonapro, sans-serif !important;
  background: var(--theme-2100);
  font-size: 14px;
  line-height: 1.25;
  overflow: auto;
}

p {
  margin: 0 !important;
}

.modal {
  overflow: hidden !important;
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.ant-message {
  z-index: 20000 !important;
}

.ant-table-placeholder {
  background: transparent !important;
}

.ant-empty-description {
  color: #ffffff;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

.animate__spin {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}

#app {
  min-height: 100vh;
  padding: 75px 0 320px 260px;
  background: var(--theme-header);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;

  scrollbar-width: thin; /* 滚动条宽度 */
  scrollbar-color: val(--primary-color) val(--the); /* 滚动条颜色 */
  &::-webkit-scrollbar-thumb {
    background-color: val(--primary-color); /* 滚动条滑块颜色 */
  }
  &::-webkit-scrollbar-track {
    background-color: val(--the); /* 滚动条轨道颜色 */
  }
}

.app-content {
  margin-bottom: 32px;
  padding: 32px;
}

.text-green {
  color: var(--theme-green) !important;
}

@media screen and (min-width: 1028px) {
  html::-webkit-scrollbar {
    height: 5px;
    width: var(--scrollbar-size);
  }
  html::-webkit-scrollbar-thumb {
    background: var(--theme-green) !important;
    border-radius: 10px;
  }
  html::-webkit-scrollbar-track {
    background-color: var(--the); /* 滚动条轨道颜色 */
  }
}
@media screen and (max-width: 1028px) {
  #app {
    padding: 0 !important;
  }

  .app-content {
    margin-bottom: 60px !important;
    padding: 16px !important;
  }
}

.app-content-pad-0 {
  padding: 0 !important;
}
</style>
