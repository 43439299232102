export default {
  currency: 'BRL',
  zone: '55',
  interface: {
    '2005': 'Erro de Captcha', // 验证码错误
    '2004': 'Código de verificação inválido',// 验证码无效
    '2008': 'O login falhou demasiadas vezes, tente novamente mais tarde', // 登录失败次数过多，请稍后再试
    '2003': 'O usuário está desativado, entre em contato com o atendimento ao cliente',// 用户被禁用，请联系客服
    '2002': 'Falha no registro, nome de usuário duplicado', // 注册失败，用户名称重复
    '2001': 'Nome de usuário ou senha incorretos', // 用户名或密码错误
    '500': 'Erro no servidor, entre em contato com o administrad', // 服务器错误，请联系管理员
    '401': 'Desculpe, o login expirou, faça o login novamente', // token失效，请重新登录
    "4010": 'Cartão bancário errado', // 银行卡错误
    "4011": 'Não cumprimento do valor mínimo de retirada única', // 不符合单笔最小提取金额
    "4012": 'Não cumprimento do valor máximo de retirada única', // 不符合单笔最大提取金额
    "4013": 'Não conformidade com o valor total máximo de retirada do dia', // 不符合当日最大提取总金额
    "4014": 'Não conformidade com o número máximo total de retiradas do dia', // 不符合当日最大提取总次数
    "4015": 'A quantidade de codificação não atingiu o padrão', // 打码量未达到标准
    "4016": 'Falta de saldo', // 余额不足
    "4020": 'Verifique se o número do cartão e o nome da conta estão corretos antes de reenviar.', // 请检查银行卡号和账户名是否正确再重新提交
    "4022": 'Ainda há pedidos em análise, tente novamente mais tarde.', // 还有审核中提现订单，请稍后再试
    "6000": 'Levantamentos frequentes, aguarde 10S e tente novamente.', //
    "4017": 'Não é possível retirar sem recarga bem-sucedida', // 未充值成功过不能够提现
    "4018": 'Não permitir que diferentes utilizadores adicionem o mesmo cartão bancário', // 不允许不同用户添加相同银行卡
    "4019": 'Renomeação de nomes de beneficiários de cartões bancários', // 银行卡收款姓名的重名
    "4004": 'O usuário não existe, registre-se',
    "4009": 'O número de cartões bancários é muito grande para continuar a adicionar, entre em contato com o atendimento ao cliente', // 银行卡数量过多，不能继续添加，请联系客服
  },
  login: {
    userName: 'Número de telefone ou endereço de e-mail',
    password: 'Senha',
    captcha: 'Código de Verificação',
    reset: 'Esqueci minha senha',
    btn: 'Login'
  },
  registrar: {
    userName: 'Número de telefone',
    password: 'Senha',
    inviteCode: 'Código de convite',
    captcha: 'Código de Verificação',
    btn: 'Comece o jogo'
  },
  red: {
    "-1": 'Pacote vermelho reivindicado', // 红包领取完毕
    "-2": 'Se não atender às regras definidas pelo pacote vermelho, o usuário atual não poderá recebê-lo', // 不符合红包设定的规则， 当前用户不能领取
    "2": 'Eu já o reivindiquei. Você só pode reivindicá-lo uma vez por pessoa.',
    "0": 'Coleta bem-sucedida de pacotes vermelhos'
  },
  wCasino: {
    more: 'Mostrar tudo'
  },
  convidar: {
    head: 'Plano recomendado',
    tabs: {
      tab1: 'Convidar',
      tab2: 'Estatísticas',
      tab3: 'Formulários',
    },
    share: {
      title: 'CONVIDE UM PARCEIRO',
      subTitle: 'URL do convite:',
      subTitle1: 'Compartilhar via:'
    },
    bouns: {
      inviteUsers: 'Convidar usuários',
      depositUsers: 'Deposit Users',
      todayBonus: 'Bônus de hoje',
      yesterDayBonus: 'Bônus de Ontem'
    },
    some: {
      title: 'Metas de receita mensal',
      fl: 'BRL 0',
      desc: '5 mais pessoas para convidar antes que o objetivo seja alcançado'
    },
    convite: {
      tit: 'COMO FUNCIONA O BÔNUS DOS CONVITES?',
      subTit: 'Bônus de Convite',
      desc: 'Cada usuário de depósito pode receber pelo menos',
      desc1: 'para cada convite. Quanto maior o valor do depósito, maior o bônus de convite para o nível correspondente.',
      people: 'Valor do depósito',
      amount: 'Bônus'
    },
    funciona: {
      tit: 'COMO FUNCIONA O BÔNUS DOS CONVITES?',
      desc: 'Esta será sua renda a longo prazo e você receberá uma porcentagem diferente de comissão cada vez que um jogador que você convidar fizer uma aposta',
      desc1: 'Bônus Rules：All Os jogadores no nível "Promotor" recebem uma porcentagem do bônus de borda da plataforma para cada aposta',
      desc2: 'As proporções dos 3 níveis são as seguintes: follows：',
      leavel1Lef: '-Nível 1：Obter',
      leavel1Rig: 'vantagem da plataforma',
      leavel2Lef: '-Nível 2：Obter',
      leavel2Rig: 'vantagem da plataforma',
      leavel3Lef: '-Nível 3：Obter',
      leavel3Rig: 'vantagem da plataforma',
    },
    renda: {
      tit: 'CALCULADORA DE RENDA',
      amount: 'BRL 7.9',
      desc: 'CALCULADORA DE RENDA 100 usuários ativos Receita anual esperada'
    },
    leader: {
      tit: 'Leaderboard',
      amount: 'BRL',
      top: 'TOP',
      username: 'User'
    },
    records: {
      tit: 'CALCULADORA DE RENDA',
      currency: 'BRL',
      desc: 'Receba um bônus de convite',
      zone: '+55'
    }
  },
  statistics: {
    tab: {
      tabTodos: 'Todos',
      tabHoje: 'Hoje',
      tabOntems: 'Ontems',
      tabAll: 'Semana passada'
    },
    nivel0: 'Todas as estatísticas',
    nivel1: 'Nível 1',
    nivel2: 'Nível 2',
    nivel3: 'Nível 3',
    invitationNumber: 'Convidado',
    validBet: 'Apostas válidas',
    depositAmount: 'Valor do depósito',
    commission: 'Comissão',
    inviteRechargeUserNum: 'Deposit Users'
  },
  forms: {
    selectGift: 'Receba um bônus de convite',
    selectComos: 'Receber um bônus de comissão',
    tableTempo: 'Tempo',
    tableUser: 'Usuário',
    tableAmount: 'Bônus'
  },
  column: {
    casino: 'Casino', // 首页大厅
    convidar: 'Convidar', // 邀请模块
    gift: 'Promoções', // 活动模块
    bouns: 'bouns', //
    wallet: 'Carteira', // 充值模块
    vip: 'VIP', // VIP模块
    caseBack: 'Cash Back',
    tarn: 'Transação',
    note: 'Notificações',
    setting: 'Colocação',
    sever: 'Suporte ao vivo',
    favourites: 'Favourites',
    recent: 'Recent',
    home: 'Home',
    inHouse: 'In-House',
    slots: 'Slots',
    liveCasino: 'Live Casino',
    pGSlots: 'PG Slots',
    fish: 'Fish'
  },
  logout: 'Logout',
  logOutComp: {
    desc: 'Não se esqueça de conferir nossa enorme seleção de cassinos e promoções esportivas antes de sair!'
  },
  signUp: 'Sign Up',
  winNav: {
    navigation: 'Navigation',
    casino: 'Casino'
  },
  winFooter: {
    support: 'Support',
    top: 'Top'
  },
  vipModal: {
    progTit: 'Seu progresso VIP',
    vip: 'VIP',
    tit: 'Mais benefícios Vip',
    desc: 'Seu progresso é uma soma acumulada através de sua aposta tanto em nosso cassino quanto em nossa cartilha esportiva. Aumentar através dos níveis lhe dá direito a maiores recompensas e tratamento VIP exclusivo',
    notice: 'Todas as apostas liquidadas progredirão mais rapidamente. Não estão incluídas as apostas que se liquidam como nulas',
    benefs: 'Benefícios',
    maxWithdraw: 'Valor máximo de saque em uma única transação',
    maxDayWithdrawCount: 'Retiradas diárias Count',
    upgradeGift: 'bolsa de promoção',
    recharge: 'Montante de recarga necessário',
    minWithdraw: 'Valor mínimo de saque único'
  },
  bottomNav: {
    menu: 'Menu',
    bonus: 'Bonus',
    deposit: 'Deposit',
    invite: 'Invite',
    casino: 'Casino'
  },
  bonusModal: {
    tit: 'Congratulations Get',
    subTit: 'Um Grande Gratuito Bônus!',
    btn: 'Get Now'
  },
  addCard: {
    tit: 'Adicionar conta bancária',
    cardTypeLabel: 'Tipo de conta',
    cardType: 'Brazil PIX',
    customerName: 'por favor, digite seu nome verdadeiro',
    customerNameLabel: 'Nome',
    cardNoLabel: 'CPF(11 dígitos) ou CNPJ(14 dígitos)',
    btn: 'Submeter'
  },
  winWallet: {
    tit: 'Minha carteira',
    tab1: 'Depósito',
    tab2: 'Retirar',
    tab3: 'Carteira Central',
    tab4: 'Prêmio'
  },
  winDep: {
    tit: 'Canal Depósito',
    subTit: 'Valor do depósito',
    depDesc: 'Valor mínimo de depósito:',
    titBonus: 'Bônus de depósito',
    titApo: 'Aposta necessária',
    titCheck: 'Não participar das atividades alvo',
    btn: 'Depósito',
    reBack: 'Back to Recharge',
    btnBack: 'Back',
    orderDesc: 'O pedido foi criado com sucesso, clique no botão abaixo para pagar!',
    payBtn: 'ir para pagar',
    depList: 'Registro de Depósito',
    minDepTips: 'Valor mínimo de depósito:'
  },
  winCentralComp: {
    userBalance: 'Asserte Total',
    betBalance: 'volume de codificação',
    cumulativeCommission: 'fechar uma conta Comissão',
    codeAmount: 'sem limite'
  },
  winPre: {
    table: {
      name: 'Name',
      quantia: 'Quantia',
      status: 'Status',
      tempo: 'Tempo'
    },
    vip_upgrade: 'VIP bolsa de promoção',
    recharge_gift: 'receber um bónus ao carregar',
    first_recharge_gift: 'Bónus de primeira descarga',
    invite_gift: 'Convites de cortesia',
    commission1: 'Nível 1 comissões',
    commission2: 'Nível 2 comissões',
    commission3: 'Nível 3 comissões',
    giveStatus1: 'reservado',
    giveStatus2: 'ainda não vencido'
  },
  winRetirar: {
    userBalanceDesc: 'Por favor, faça um depósito antes de tentar sacar.',
    userBalanceTit: 'Saldo disponível',
    retirarDesc: 'Valor do saque',
    subTit: 'Retirada para',
    addCardTit: 'Adicionar conta bancária',
    checkTit: 'Consentimento de utilização',
    minWithdraw: 'Montante mínimo de saque :',
    maxWithdraw: 'Montante máximo de saque :',
    commissionAmount: 'Taxa',
    finallyAmount: '(Você Vai Receber:',
    codeAmountLeft: 'Necessidade adicional(',
    codeAmountRig: ') montante da aposta a retirar',
    btn: 'Submeter',
    columns: {
      name: 'Locais Excluídos',
      number: 'Aposta necessária'
    },
    registroTit: 'Registro de Depósito'
  },
  trade: {
    tit: 'Transações',
    tab1: 'Depósitos',
    tab2: 'Retiradas',
    tab3: 'Apostas',
    tab4: 'Detalhe de saldo',
  },
  winApost: {
    columns: {
      name: 'GAME',
      bet: 'BET AMOUNT',
      payout: 'PAYOUT',
      time: 'TIME',
    },
    tit: 'PROVIDER BET'
  },
  winDepos: {
    desc: 'Nenhuma entrada.',
    status0: 'Esperando para recarregar',
    status1: 'Sucesso',
    status2: 'fracasso',
  },
  winDetaIhe: {
    tit: 'Filtrar por',
    desc: 'Nenhuma entrada.',
    invite_gift: 'Convites de cortesia',
    recharge_gift: 'receber um bónus ao carregar',
    first_recharge_gift: 'Bónus de primeira descarga',
    orderBh: 'Pedido de retirada falhado',
    chongZhi: 'recarga',
    vipSj: 'VIP bolsa de promoção',
    yongJin: 'comissões',
    huiYTx: 'Retiradas dos membros',
    shouUp: 'Aumento sistemático dos saldos',
    shouDown: 'Redução sistemática de saldos'
  },
  traWithdraw: {
    desc: 'Nenhuma entrada.',
    status20: 'Pedido de retirada em curso',
    status10: 'Pedido de retirada em curso',
    status21: 'Pedido de retirada em curso',
    status01: 'Retirada com êxito',
    status02: 'Não disponível, verifique os dados do seu cartão',
    status403: 'Não disponível, verifique os dados do seu cartão',
  },
  favorites: {
    tit: 'Favoritos',
    empty: 'Clique para coletar seus jogos favoritos e encontrá-los aqui facilmente',
  },
  recente: {
    tit: 'Favoritos',
    empty: 'Clique para coletar seus jogos favoritos e encontrá-los aqui facilmente',
  },
  prevBtn: 'Anterior',
  nextBtn: 'Próximo',
}
