<template>
  <div class="slider" ref="slider">
    <div class="slider-group" ref="sliderGroup">
      <!-- 父组件中的内容将插入到 slot 所在的 DOM 位置，并替换掉 slot 标签本身 -->
      <slot> </slot>
    </div>
    <div class="dots">
      <span
        class="dot"
        v-for="(item, index) in dots"
        :key="item"
        :class="{ active: currentPageIndex === index }"
      ></span>
    </div>
  </div>
</template>

<script>
import BScroll from 'better-scroll'
import { addClass } from '@/common/js/dom'
import fastclick from 'fastclick'

export default {
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      dots: 0,
      currentPageIndex: 0,
    }
  },
  mounted() {
    fastclick.attach(this.$refs.slider)

    // 同 this.$nextTick()，确保DOM已被渲染
    setTimeout(() => {
      this._setSliderWidth()
      this._initDots()
      this._initSlider()

      // 初始轮播
      if (this.autoPlay) {
        this._play()
      }
    }, 20)

    // 窗口大小改变后，重设 slider
    window.addEventListener('resize', () => {
      if (!this.slider) {
        return
      }
      this._setSliderWidth(true)
      this.slider.refresh()
    })
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    _setSliderWidth(isResize) {
      this.children = this.$refs?.sliderGroup?.children

      let width = 0
      // clientWidth 包括 content + padding
      let sliderWidth = this.$refs?.slider?.clientWidth
      for (let i = 0; i < this.children?.length; i++) {
        let child = this.children[i]
        addClass(child, 'slider-item')

        child.style.width = sliderWidth + 'px'
        width += sliderWidth
      }

      // 循环轮播，初始时额外添加2个宽度
      if (this.loop && !isResize) {
        width += 2 * sliderWidth
      }
      if (this.$refs?.sliderGroup?.style) {
        this.$refs.sliderGroup.style.width = width + 'px'
      }
    },
    _initDots() {
      this.dots = this.children?.length
    },
    _initSlider() {
      this.slider = new BScroll(this.$refs.slider, {
        scrollX: true, // 允许横向滚动
        scrollY: false, // 不允许纵向滚动
        momentum: false, // 无惯性
        snap: true, // slide专用
        snapLoop: this.loop, // 会添加首尾两个 DOM
        snapThreshold: 0.3, // 滚动阈值
        snapSpeed: 400, // 滚动速度
      })

      // 切换到下一张时触发 better-scroll event
      this.slider.on('scrollEnd', () => {
        let pageIndex = this.slider.getCurrentPage().pageX
        if (this.loop) {
          pageIndex -= 1
        }
        this.currentPageIndex = pageIndex

        // 继续轮播
        if (this.autoPlay) {
          clearTimeout(this.timer)
          this._play()
        }
      })
    },
    _play() {
      let pageIndex = this.currentPageIndex + 1
      if (this.loop) {
        pageIndex += 1
      }
      this.timer = setTimeout(() => {
        // goToPage(x, y, time, easing) is from better-scroll
        this.slider.goToPage(pageIndex, 0, 400)
      }, this.interval)
    },
  },
}
</script>

<style scoped lang="scss">
.slider {
  min-height: 1px;
  .slider-group {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    .slider-item {
      float: left;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
      a {
        display: block;
        width: 100%;
        overflow: hidden;
        text-decoration: none;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .dots {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 12px;
    text-align: center;
    font-size: 0;
    .dot {
      display: inline-block;
      margin: 0 4px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: hsla(0, 0%, 100%, 0.3);
      &.active {
        width: 8px;
        border-radius: 5px;
        background: #fff;
      }
    }
  }
}
</style>
