import cookies from '@/JS/util.cookies.js';

let userInfo;
if (cookies.get('userInfo')) {
  if (typeof cookies.get('userInfo') === 'string') userInfo = JSON.parse(cookies.get('userInfo'))
}

let siteInfo;
if (cookies.get('siteInfo')) {
  if (typeof cookies.get('siteInfo') === 'string') siteInfo = JSON.parse(cookies.get('siteInfo'))
}

let gameSingle;
if (cookies.get('gameSingle')) {
  if (typeof cookies.get('gameSingle') === 'string') gameSingle = JSON.parse(cookies.get('gameSingle'))
}

let telegramList;
if (cookies.get('telegramList')) {
  if (typeof cookies.get('telegramList') === 'string') telegramList = JSON.parse(cookies.get('telegramList'))
}

const testImgUrl = 'https://pg.imgix.net/';
const protImgUrl = 'https://666.imgix.net/';

export default {
  uid: cookies.get('userId') || '',
  userInfo: userInfo || '',
  gameSingle: gameSingle || '',
  lang: 'pt', // 葡萄牙语,
  userBalance: '',
  systemSetting: cookies.get('systemSetting') || '',
  siteInfo: siteInfo || '',
  telegramList: telegramList || '',
  imgUrl: protImgUrl,
  siteLogo: cookies.get('siteLogo') || '',
  partiesObj: {
    clazz: {
      nameI18nJson: {en: 'Game Provider', zh: 'Game Provider'},
      provider: true,
      code: 'all'
    },
    games: [
      {
        logo: require('@/assets/casino/pg.png'),
        isLocal: true,
        nameI18nJson: {en: 'PG Slots', zh: 'PG Slots'},
        codeId: "7",
        code: 'all',
        vaneCode: 'slots'
      },
      {
        logo: require('@/assets/casino/pragmaticplay.png'),
        isLocal: true,
        codeId: "6",
        nameI18nJson: {en: 'PragmaticPlay-Slots', zh: 'PragmaticPlay-Slots'},
        code: 'all',
        vaneCode: 'slots'
      },
      {
        logo: require('@/assets/casino/EVOplay.png'),
        isLocal: true,
        codeId: "31",
        nameI18nJson: {en: 'EVO-play', zh: 'EVO-play'},
        code: 'all',
        vaneCode: 'slots'
      },
      {
      	logo: require('@/assets/casino/bagming.png'),
      	isLocal: true,
      	codeId: "15",
      	nameI18nJson: {en: 'Bgaming-Slots', zh: 'Bgaming-Slots'},
      	code: 'all',
      	vaneCode: 'slots'
      },
      {
        logo: require('@/assets/casino/JILIgaming.png'),
        isLocal: true,
        codeId: "14",
        nameI18nJson: {en: 'Tada Slots', zh: 'Tada Slots'},
        code: 'all',
        vaneCode: 'slots'
      },
      {
        logo: require('@/assets/casino/KAgaming.png'),
        isLocal: true,
        codeId: "2",
        nameI18nJson: {en: 'Ka-Slots', zh: 'Ka-Slots'},
        code: 'all',
        vaneCode: 'slots'
      },
      {
        logo: require('@/assets/casino/cq9.png'),
        isLocal: true,
        codeId: "1",
        nameI18nJson: {en: 'CQ9-Slots', zh: 'CQ9-Slots'},
        code: 'all',
        vaneCode: 'slots'
      },
    ]
  }
}
