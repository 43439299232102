<template>
  <div class="modal" @click.stop="close">
    <div class="container">
      <div class="header">
        <div class="title">{{noticesData.titile}}</div>
        <div class="close" @click="close">
          <img src="@/assets/images/close.png" alt="" />
        </div>
      </div>
      <div class="content" v-html="noticesData.msgContent">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noticesData: {
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1028px) {
  .container {
    width: 30em;
  }
}

@media screen and (max-width: 1028px) {
  .container {
    width: 90%;
  }
}
.modal {
  backdrop-filter: saturate(180%) blur(5px);
  background-color: rgba(0, 0, 0, 0.45);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 4000;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    background: var(--theme-2000);
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 4px;
    .header {
      padding: 16px 16px 0 16px;
      position: relative;
      .title {
        font-family: zonapro-thin, sans-serif;
        text-align: center;
        font-size: var(--font-size-xl);
        color: #42a5f5 !important;
      }
      .close {
        min-height: 2.4em;
        min-width: 2.4em;
        border-radius: 100%;
        position: absolute;
        right: -14px;
        top: -14px;
        cursor: pointer;
        user-select: none;
        background: var(--theme-200);
        outline: 0 !important;
        color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.12);
        img {
          width: 1.715em;
        }
      }
    }
    .content {
      font-family: zonapro-thin, sans-serif;
      padding: 16px 16px 0 16px;
      p {
        color: #fff;
        text-align: left;
      }
      a {
        color: #177ddc;
        text-decoration: none;
      }
    }
  }
}
</style>
