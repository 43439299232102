import axios from 'axios'
import qs from 'qs';
import vm from '@/main'
import { get } from 'lodash'
import log from '@/JS/util.log'
import cookies from '@/JS/util.cookies.js'
/**
 * @description 创建请求实例
 */
function createService () {
  // 创建一个 axios 实例
  const service = axios.create({
    timeout: 30 * 1000 // 请求超时时间
  })

  // 请求拦截
  service.interceptors.request.use(
    config => {
      let token = cookies.get('token');
      // 在请求发送之前做一些处理，统一添加ticket, appName参数
      if (config.method === 'post' && config.headers['Content-Type'] === 'multipart/form-data') {
        return config
      }

      // 接口没返回时显示loading
      vm.$loading.hide()
      vm.$loading.show()
      config.headers['X-Requested-With'] = 'XMLHttpRequest'
      config.headers['Content-Type'] = config.contType === 2 ? 'application/x-www-form-urlencoded' : 'application/json'
      if (token) config.headers['X-Access-Token'] = token
      // if (config.method !== 'get') {
      //   config.data = config.data;
      // }

      return config
    },
    error => {
      // 发送失败
      log.danger(error)
      vm.$loading.hide()
      return Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data
      // 这个状态码是和后端约定的
      const code = dataAxios.code
      vm.$loading.hide()
      // 根据 code 进行判断
      return dataAxios
      // if (code === undefined) {
      //   // 如果没有 code 代表这不是项目后端开发的接口
      //   return dataAxios
      // } else {
      //   // 有 code 代表这是一个后端接口 可以进行进一步的判断
      //   switch (code) {
      //     case 200:
      //       // [ 示例 ] code === 200 代表没有错误
      //       return dataAxios
      //     case 400:
      //       // [ 示例 ] 其它和后台约定的 code
      //       // window.location.href = `${process.env.VUE_APP_PAGE_HOST}/login`
      //       // errorCreate(`[ code: 401 ] ${dataAxios.msg}: ${response.config.url}`)
      //       break;
      //     default:
      //       // 不是正确的 code
      //       log.primary(`${dataAxios.msg}: ${response.config.url}`)
      //       break
      //   }
      // }
    },
    error => {
      const status = get(error, 'response.status')
      switch (status) {
        case 400:
          error.message = '请求错误';
          break
        case 401:
          vm.$message.error('Desculpe, o login expirou, faça o login novamente');
          vm.$store.commit('setLogoutUser');
          window.openLogin({isOutOpen: true});
          error.message = '未授权，请登录';
          break
        case 403:
          error.message = '拒绝访问';
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break
        case 408:
          error.message = '请求超时';
          break
        case 500:
          error.message = '服务器内部错误';
          break
        case 501:
          error.message = '服务未实现';
          break
        case 502:
          error.message = '网关错误';
          break
        case 503:
          error.message = '服务不可用';
          break
        case 504:
          error.message = '网关超时';
          break
        case 505:
          error.message = 'HTTP版本不受支持';
          break
        default: break
      }
      log.danger(error)
      vm.$loading.hide()
      return Promise.reject(error)
    }
  )
  return service
}


// 用于真实网络请求的实例和请求方法
export const service = createService()

// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService()

