<template>
  <div class="tabs" :class="size">
    <div
      v-for="(item, index) in tabs"
      :key="item.value"
      class="tab"
      :class="[item.value === activeTab ? 'active' : '', size]"
      :style="item.value === activeTab ? tabActiveStyle : tabStyle"
      @click="changeActive(item.value, index)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
    },
    tabs: {
      type: Array,
    },
    activeTab: {
      type: String,
    },
    tabStyle: {
      type: Object,
    },
    tabActiveStyle: {
      type: Object,
    },
  },
  data() {
    return {
      activeValue: '',
    }
  },
  methods: {
    changeActive(value, index) {
      this.$emit('changeActive', value, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  border-radius: 8px;
  padding: 4px;
  display: inline-flex;
  background: var(--theme-600);
  flex-wrap: nowrap;
  vertical-align: middle;
  max-width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &.small {
  }
  .tab {
    height: 44px;
    padding: 9px 16px 8px 16px;
    font-size: 14px;
    margin-top: 1px;
    border-radius: 8px;
    color: #fff !important;
    cursor: pointer;
    user-select: none;
    text-wrap: nowrap;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 500;
    background: var(--theme-600);
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    &.small {
      font-family: zonapro-thin, sans-serif;
      height: 33px;
      font-size: 10px;
    }
  }
  .active {
    background: var(--theme-green);
    &:hover {
      background: var(--theme-green);
      opacity: 0.9;
    }
  }
}
</style>
