<template>
  <div class="bottom-nav">
    <div class="container">
      <div
        class="nav-tab"
        :class="{ active: activeNav === 'menu' }"
        @click="toPage('menu', '')"
      >
        <span class="icon">
          <svg viewBox="0 0 24 24" focusable="false">
            <use xlink:href="@/assets/icons.svg#icon-menu"></use>
          </svg>
        </span>
        <span class="desc"> {{ $t('bottomNav.menu') }} </span>
      </div>
      <div
        class="nav-tab"
        :class="{ active: activeNav === 'bonus' }"
        @click="toPage('bonus', '/promotions')"
      >
        <span class="icon">
          <svg viewBox="0 0 24 24" focusable="false">
            <use xlink:href="@/assets/icons.svg#icon-promotions"></use>
          </svg>
        </span>
        <span class="desc"> {{ $t('bottomNav.bonus') }} </span>
      </div>
      <div class="nav-tab" @click="toPage('', '/pages/wallet/index')">
        <div class="deposit">
          <img src="@/assets/images/wallet.gif" />
          <span class="desc"> {{ $t('bottomNav.deposit') }} </span>
        </div>
      </div>
      <div
        class="nav-tab"
        :class="{ active: activeNav === 'invite' }"
        @click="toPage('invite', '/convidar')"
      >
        <span class="icon">
          <img src="@/assets/images/share.svg" />
        </span>
        <span class="desc"> {{ $t('bottomNav.invite') }} </span>
      </div>
      <div
        class="nav-tab"
        :class="{ active: activeNav === 'casino' }"
        @click="toPage('casino', '/')"
      >
        <span class="icon">
          <svg viewBox="0 0 24 24" focusable="false">
            <use xlink:href="@/assets/icons.svg#icon-casino"></use>
          </svg>
        </span>
        <span class="desc"> {{ $t('bottomNav.casino') }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      activeNav: 'menu',
    }
  },
  computed: {
    ...mapState({
      uid: state => state.uid,
    }),
  },
  methods: {
    toPage(type, link) {
      const { uid } = this;
      if (!uid) {
        if (link === '/') {
          this.$router.push({
            path: link
          })
        } else {
          this.$emit('setShowLoginModal', true);
        }
      } else {
        this.activeNav = type;
        if (!link) {
          this.$emit('setShow', true);
        } else {
          this.$router.push({
            path: link
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-nav {
  display: none;
}

@keyframes GbTabBar_blink__PShTN-91d5eee0 {
  0% {
    transform: scale(0.74);
  }

  25% {
    transform: scale(0.83);
  }

  50% {
    transform: scale(0.74);
  }

  75% {
    transform: scale(0.83);
  }

  to {
    transform: scale(0.74);
  }
}

@media screen and (max-width: 1028px) {
  .bottom-nav {
    width: 100%;
    height: 60px;
    background: var(--theme-600);
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    .container {
      width: 100%;
      height: 100%;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      height: 60px;
      overflow: visible;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .nav-tab {
        height: 100%;
        flex: 1;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        user-select: none;
        &.active {
          color: var(--theme-green);
        }
        &:hover,
        &:active {
          background: rgba(255, 255, 255, 0.1);
        }
        .deposit {
          margin-top: -20px;
          height: 68px;
          width: 68px;
          // position: absolute;
          background: linear-gradient(
            180deg,
            var(--theme-green),
            var(--theme-2100)
          );
          border: 6px solid var(--theme-2100);
          border-radius: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            height: 24px;
            width: 24px;
            object-fit: contain;
          }
          .desc {
            font-size: 12px;
            animation: GbTabBar_blink__PShTN-91d5eee0 1.5s ease 0s infinite
              normal none running;
          }
        }
        .icon {
          width: 12.8px;
          height: 12.8px;
          img {
            width: 12.8px;
            height: 12.8px;
          }
          svg {
            width: 12.8px;
            height: 12.8px;
            fill: currentColor;
          }
        }
        .desc {
          margin-top: 4px;
          color: currentColor;
          font-size: var(--font-size-xs);
        }
      }
    }
  }
}
</style>
