<template>
  <div class="bonus-modal">
    <div class="modal-content">
      <div class="title">{{ $t('bonusModal.tit') }}</div>
      <div class="subTitle">{{ $t('bonusModal.subTit') }}</div>
      <div class="promo-btn" @click="receiveRed">{{ $t('bonusModal.btn') }}</div>
      <div class="close" @click="closeRed">
        <svg viewBox="0 0 24 24" focusable="false">
          <use xlink:href="@/assets/yebet.svg#icon-close-circle"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    redId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      uid: state => state.uid
    }),
  },
  methods: {
    ...mapMutations(['setUserBalance']),
    closeRed() {
      this.$emit('setShowBonusModal', false)
    },
    showTopTips(code) {
      this.$message.info(this.$t(`red.${code}`));
    },
    watchBalance() {
      const { uid } = this;
      this.$api.getVipUserInfo({id: uid})
      .then(res => {
        if (res.success === true) {
          this.setUserBalance(res.result.balance.balance.toFixed(2));
        }
      }).catch(err => {
        this.$message.error('Erro do sistema.Tente novamente mais tarde.');
      })
    },
    receiveRed() {
      const { uid } = this;
      if (!uid) return window.openLogin({isOutOpen: true});
      this.$api.redEnvelopes(this.redId).then(res => {
        if (res.success === true) {
          this.showTopTips(res.result.code);
          this.closeRed();
          if (res.result.code === '0') this.watchBalance();
        }
      }).catch(err => {
        this.closeRed();
        this.$message.error('Faça o login primeiro');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.bonus-modal {
  backdrop-filter: saturate(180%) blur(10px);
  background: rgba(0, 0, 0, 0.55);
  opacity: 1;
  display: block;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4005;
}
.modal-content {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 1;
  background-image: url('@/assets/images/bonus_dialog_bg.png');
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  height: 411px;
  padding: 184px 40px 20px;
  width: 313px;
}
.title {
  color: #fff;
  font-size: 17px;
  // font-weight: 700;
  text-align: center;
  font-family: proxima-nova, sans-serif;
}
.subTitle {
  color: #eec639;
  font-size: 30px;
  line-height: 38.75px;
  font-weight: 700;
  text-align: center;
  font-family: proxima-nova, sans-serif;
}
.promo-btn {
  background-image: url('@/assets/images/promo_btn.png');
  align-items: center;
  color: #a2140d;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  height: 42px;
  justify-content: center;
  margin-top: 20px;
  width: 190px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue;
  cursor: pointer;
}
.close {
  bottom: -45px;
  font-size: 24px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  cursor: pointer;
  user-select: none;
  svg {
    width: 24px;
    height: 24px;
    color: #fff;
  }
}
</style>
