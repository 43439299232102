<template>
  <div class="footer">
    <div class="content" v-if="footerInfo">
      <img
        class="mobile-winiu7"
        :src="`${imgUrl}${siteLogo}`"
        style="width: 80px; height: 80px"
      />
      <div class="pic">
        <img
          class="winiu7"
          :src="`${imgUrl}${siteLogo}`"
          style="width: 80px; height: 80px"
        />
        <img src="@/assets/images/1.png" />
        <img src="@/assets/images/2.png" />
        <img src="@/assets/images/3.png" />
        <img src="@/assets/images/4.png" />
        <img src="@/assets/images/5.png" />
        <img src="@/assets/images/6.png" />
        <img src="@/assets/images/7.png" />
        <img src="@/assets/images/8.png" />
      </div>
      <div class="desc">
        {{footerInfo.description}}
      </div>
      <div class="email">
        {{ $t('winFooter.support') }}:
        <a :href="footerInfo.support">{{footerInfo.support}}</a>
      </div>
      <div class="bottom">
        <div class="rights">
          <span class="icon"> <img src="@/assets/images/18+.svg" /></span>
          <span class="icon"> <img src="@/assets/images/gc.png" /> </span>
          <span class="icon"> <img src="@/assets/images/twitter.svg" /> </span>
          <span class="icon"> <img src="@/assets/images/f.svg" /> </span>
          <span class="icon"> <img src="@/assets/images/camera.svg" /> </span>
          <span class="icon"> <img src="@/assets/images/tiktok.svg" /> </span>
          <span class="icon"> <img src="@/assets/images/phone.svg" /> </span>
          <span class="icon">
            <img src="@/assets/images/video.svg" />
          </span>
        </div>
        <span class="rights-desc"
          >{{footerInfo.version}}</span
        >
      </div>
    </div>
    <div class="to-top" @click="toTop">
      <span class="top-icon">
        <svg viewBox="0 0 24 24" focusable="false">
          <use xlink:href="../assets/bcfun.svg#icon_Arrow"></use>
        </svg>
      </span>
      <span class="top">{{ $t('winFooter.top') }}</span>
    </div>
  </div>
</template>

<script>
// import ICONSVG2 from '@/assets/images/2.svg'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      footerInfo: ''
    }
  },
  computed: {
    ...mapState({
      siteInfo: state => state.siteInfo,
      siteLogo: state => state.siteLogo,
      imgUrl: state => state.imgUrl
    }),
  },
  watch: {
    siteInfo(newVal) {
      this.footerInfo = (newVal == null ? {} : newVal.site)
    }
  },
  created() {
    this.footerInfo = this.siteInfo.site;
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // 添加平滑滚动效果
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  font-family: zonapro-thin, sans-serif;
  width: auto;
  position: absolute;
  left: 260px;
  right: 0;
  bottom: 0;
  background: var(--theme-1700);
  margin: 32px;
  border-radius: 16px;
  padding: 32px;
  color: var(--grey-200);
  .content {
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    .mobile-winiu7 {
      display: none;
    }
    .pic {
      display: flex;
      align-items: center;
      img {
        height: 22px;
        margin-right: 8px;
      }
      .winiu7 {
        margin-right: 15px;
        display: block;
      }
    }
    .desc {
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #7c7c95;
      margin-top: 12px;
      max-width: 465px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .email {
      margin-left: 12px;
      margin-bottom: 10px;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #7c7c95;
      a {
        color: #33c38f;
        text-transform: none;
        text-decoration: none;
      }
    }
    .bottom {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .rights {
        height: 54px;
        display: inline-flex;
        align-items: center;
        color: #adadc2;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        .icon {
          margin-right: 9px;
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
  .to-top {
    position: absolute;
    right: 32px;
    bottom: 32px;
    width: 3.375rem;
    height: 3.375rem;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--theme-2800);
    color: var(--title-color);
    cursor: pointer;
    user-select: none;
    .top-icon {
      width: 1.4em;
      height: 1.4em;
      transform: rotate(-90deg);
      font-size: 0.6875rem;
      fill: var(--primary-color);
    }
    .top {
      color: var(--title-color);
      cursor: pointer;
      user-select: none;
      font-size: 0.75rem;
      line-height: 1.5;
    }
  }
}

@media screen and (min-width: 1442px) {
  .email {
    position: absolute;
    left: 752px;
    top: 31px;
  }
}

@media screen and (max-width: 1028px) {
  .footer {
    position: relative !important;
    left: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    .content {
      align-items: center !important;
      text-align: center !important;
      .desc {
        text-overflow: unset;
        -webkit-line-clamp: unset;
      }
    }
  }
  .bottom {
    flex-direction: column !important;
  }
  .to-top {
    position: relative !important;
    margin: 4rem auto 0 calc(50% + 5px);
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 16px 16px 32px 16px;
  }
  .mobile-winiu7 {
    display: block !important;
  }
  .pic {
    justify-content: center;
    flex-wrap: wrap;
  }
  .winiu7 {
    display: none !important;
  }
}
</style>
