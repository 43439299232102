// 引入mockjs
const Mock = require("mockjs");

// 获取 mock.Random 对象
const Random = Mock.Random;

const basePath = process.env.NODE_ENV === 'development' ? '/win-api' : '/win-api';

const appJson = 1;
const appXwF = 2;

import * as tools from "@/api/_tools.js";

export default ({ service }) => ({
  clientMemberIndexBaseInfo(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/memberIndexBaseInfo",
      method: "post",
      params,
      contType: appXwF
    });
  },
  clientGamesFavourate(params = {}) {
    return service({
      url: basePath + "/clientGames/favourate",
      method: "get",
      params,
      contType: appXwF
    });
  },
  getClientGamesListPage(params = {}) {
    return service({
      url: basePath + "/clientGames/listPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  clientGamesRecentListPage(params = {}) {
    return service({
      url: basePath + "/clientGames/recentListPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  clientGamesFavourateListPage(params = {}) {
    return service({
      url: basePath + "/clientGames/favourateListPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  clientMemberRechargeInfo(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/rechargeInfo",
      method: "get",
      params,
      contType: appXwF
    });
  },
  getVipUserInfo(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/queryById",
      method: "get",
      params,
      contType: appXwF
    });
  },
  activityDetailListPage(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/activityDetailListPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  depositsListPage(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/depositsListPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  withdrawOrderList(params = {}) {
    return service({
      url: basePath + "/financial/withdrawOrderClient/list",
      method: "get",
      params,
      contType: appXwF
    });
  },
  balanceListPage(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/balanceListPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  betListPage(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/betListPage",
      method: "get",
      params,
      contType: appXwF
    });
  },
  getClientMemberRandomImage(id = '') {
    return service({
      url: basePath + "/clientMember/bizMember/randomImage/" + id,
      method: "get",
      contType: appXwF
    });
  },
  getClientMemberBankList(id = '') {
    return service({
      url: basePath + "/clientMember/bizMember/bankList/" + id,
      method: "get",
      contType: appXwF
    });
  },
  redEnvelopes(id = '') {
    return service({
      url: basePath + "/clientMember/bizMember/redEnvelopes/" + id,
      method: "get",
      contType: appXwF
    });
  },
  clientMemberDetailActivity(id = '') {
    return service({
      url: basePath + "/clientMember/bizMember/detailActivity/" + id,
      method: "get",
      contType: appXwF
    });
  },
  vipInfoLeave(id = '') {
    return service({
      url: basePath + "/clientMember/bizMember/vipInfo/" + id,
      method: "get",
      contType: appXwF
    });
  },
  withdrawLimitInfo(id = '') {
    return service({
      url: basePath + "/clientMember/bizMember/withdrawLimitInfo/" + id,
      method: "get",
      contType: appXwF
    });
  },
  loginClientMemberLogin(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/login",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  bizFinancialMemberBankAdd(params = {}) {
    return service({
      url: basePath + "/financial/bizFinancialMemberBank/add",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  addClientMemberRegister(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/register",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  getClientGamesLink(params = {}) {
    return service({
      url: basePath + "/clientGames/gameUrl",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  subWithdraw(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/subWithdraw",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  payCashPayRecharge(params = {}) {
    return service({
      url: basePath + "/pay/clientPayThree/cashPayRecharge",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  getPayChannel(params = {}) {
    return service({
      url: basePath + "/pay/clientPayThree/getPayChannel",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  inviteGiftInfo(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/inviteGiftInfo",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
  inviteDataInfo(params = {}) {
    return service({
      url: basePath + "/clientMember/bizMember/inviteDataInfo",
      method: "post",
      data: params ,
      contType: appJson
    });
  },
});
