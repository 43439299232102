<template>
  <div class="vip-modal">
    <a-modal
      class="vip-modal"
      :title="$t('vipModal.tit')"
      :centered="true"
      :closable="true"
      :footer="null"
      :visible="showVIPModal"
      @cancel="$emit('setShowVIPModal', false)"
    >
      <WTabs
        size="small"
        :tabs="tabs"
        :activeTab="activeTab"
        @changeActive="changeActive"
      ></WTabs>
      <div v-if="activeTab === 'Progresso'" class="vip-container">
        <img class="img" src="@/assets/images/vip-modal.png" />
        <div class="row mt8 bold">
          <div class="left">{{ $t('vipModal.progTit') }}</div>
          <div class="right">{{percentage}}%</div>
        </div>
        <div class="progress">
          <div class="progress-active" :style="{transform: `scale3d(${percentage / 100}, 1, 1)`}"></div>
        </div>
        <div class="row">
          <div class="left">
            <svg viewBox="0 0 24 24" focusable="false">
              <use xlink:href="@/assets/icons.svg#icon-vip-none"></use>
            </svg>
            {{ $t('vipModal.vip') }} {{userVipLevel}}
          </div>
          <div class="right">
            <svg viewBox="0 0 24 24" focusable="false">
              <use xlink:href="@/assets/icons.svg#icon-vip-bronze"></use>
            </svg>
            {{ $t('vipModal.vip') }} {{userVipLevel + 1}}
          </div>
        </div>
        <div class="desc">
          {{ $t('vipModal.desc') }}
        </div>
        <div class="notice">
          {{ $t('vipModal.notice') }}
        </div>
      </div>

      <div v-if="activeTab === 'Meu Benifício'" class="vip-container">
        <div class="bane">{{ $t('vipModal.vip') }} {{userVipLevel}} {{ $t('vipModal.benefs') }}</div>
        <div class="bane-container">
          <div class="row menu" v-if="vipList[userVipLevel]">
            <div class="left">{{ $t('vipModal.maxWithdraw') }}</div>
            <div class="right">
              {{vipList[userVipLevel].maxWithdraw}}
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="@/assets/icons.svg#icon-currency-brl"></use>
              </svg>
            </div>
          </div>
          <div class="row menu" v-if="vipList[userVipLevel]">
            <div class="left">{{ $t('vipModal.maxDayWithdrawCount') }}</div>
            <div class="right">
              {{vipList[userVipLevel].maxDayWithdrawCount}}
            </div>
          </div>
          <div class="row menu" v-if="vipList[userVipLevel]">
            <div class="left">{{ $t('vipModal.upgradeGift') }}</div>
            <div class="right">
              {{vipList[userVipLevel].upgradeGift}}
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="@/assets/icons.svg#icon-currency-brl"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'Mais benefícios Vip'" class="vip-container">
        <a-space direction="vertical" size="middle">
          <a-collapse
              v-for="(item, index) in vipList"
              :key="index"
              :bordered="false"
              collapsible="header"
          >
            <a-collapse-panel :key="index" :showArrow="false">
              <div class="collapse-header" slot="header">
                <svg
                  style="margin-right: 32px"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <use xlink:href="@/assets/icons.svg#icon-vip-gold"></use>
                </svg>
                <span style="flex: 1"> vip{{ index  }} </span>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                      fill="#adadc2"
                    ></path>
                  </g>
                </svg>
              </div>
              <div class="bene-collapse">
                <div class="row menu bene">
                  <div class="left">{{ $t('vipModal.maxWithdraw') }}</div>
                  <div class="right">
                    {{item.maxWithdraw}}
                    <svg viewBox="0 0 24 24" focusable="false">
                      <use
                        xlink:href="@/assets/icons.svg#icon-currency-brl"
                      ></use>
                    </svg>
                  </div>
                </div>
                <div class="row menu bene">
                  <div class="left">{{ $t('vipModal.maxDayWithdrawCount') }}</div>
                  <div class="right">
                    {{item.maxDayWithdrawCount}}
                  </div>
                </div>
                <div class="row menu bene">
                  <div class="left">{{ $t('vipModal.upgradeGift') }}</div>
                  <div class="right">
                    {{item.upgradeGift}}
                    <svg viewBox="0 0 24 24" focusable="false">
                      <use
                        xlink:href="@/assets/icons.svg#icon-currency-brl"
                      ></use>
                    </svg>
                  </div>
                </div>
                <div class="row menu bene">
                  <div class="left">{{ $t('vipModal.recharge') }}</div>
                  <div class="right">
                    {{item.recharge}}
                    <svg viewBox="0 0 24 24" focusable="false">
                      <use
                        xlink:href="@/assets/icons.svg#icon-currency-brl"
                      ></use>
                    </svg>
                  </div>
                </div>
                <div class="row menu bene">
                  <div class="left">{{ $t('vipModal.minWithdraw') }}</div>
                  <div class="right">50</div>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import WTabs from '@/components/WTabs'
import { mapState } from 'vuex';

export default {
  components: {
    WTabs,
  },
  props: {
    showVIPModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeTab: 'Progresso',
      tabs: [
        { label: 'Progresso', value: 'Progresso' },
        { label: 'Meu Benifício', value: 'Meu Benifício' },
        { label: 'Mais benefícios Vip', value: 'Mais benefícios Vip' },
      ],
      vipList: {},
      userVipLevel: 0,
      nexVipLevel: 0,
      nexVipData: {},
      rechargeAmount: 0,
      percentage: 0
    }
  },
  computed: {
    ...mapState({
      uid: state => state.uid,
    }),
  },
  watch: {
    showVIPModal(newValue) {
      if (newValue) this.getVipList();
      // this.tempVipLeavlShow = (newValue == null ? false : newValue)
    },
  },
  methods: {
    changeActive(value) {
      this.activeTab = value
    },
    getTit(val) {
      return 'VIP' + val;
    },
    calculatedPercentage(amout, needAmout) { // 计算百分比
      let percentage = (amout / needAmout / 2) * 100;
      percentage > 50 ? percentage = 50 : '';
      this.percentage = percentage;
    },
    calculatedBet(a1, a2) {
      let percentage = (a1 / a2 / 2) * 100;
      percentage > 50 ? percentage = 50 : '';
      this.percentage = Number((percentage + this.percentage).toFixed(1));
    },
    getVipList() {
      this.$api.vipInfoLeave(this.uid)
      .then(res => {
        if (res.success === true) {
          this.userVipLevel = Number(res.result.userVipLevel);
          this.rechargeAmount = res.result.rechargeAmount;
          this.vipList = res.result.vipInfo;
          if (this.userVipLevel !== this.vipList.length) {
            this.nexVipLevel = this.userVipLevel + 1;
            this.nexVipData = this.vipList[this.nexVipLevel];
            this.calculatedPercentage(this.rechargeAmount, this.nexVipData.recharge);
            this.calculatedBet(res.result.cumulativeAmount || 0, this.nexVipData.bet);
          }
        }
      }).catch(err => {
        this.$message.error('Erro do sistema.Tente novamente mais tarde.');
      });
    },
    tapChooseIndex(index) {
      this.tabIndex = index;
    },
  }
}
</script>

<style lang="scss">
.vip-modal {
  .ant-modal {
    width: 100% !important;
    max-width: 500px;
  }
  .ant-modal-wrap {
    padding: 24px;
  }
  .ant-modal-content {
    max-width: 500px;
    width: 100%;
    background: var(--theme-1700) !important;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
      0 1px 10px rgba(0, 0, 0, 0.12);
    overflow: scroll;
    max-height: 95vh;
    &::-webkit-scrollbar {
      display: none;
    }
    .ant-modal-header {
      font-family: zonapro-thin, sans-serif;
      background: var(--theme-1700);
      color: #fff;
      border: 0;
      height: 50px;
      padding: 8px;
      .ant-modal-title {
        margin-left: 16px;
        color: var(--grey-200);
      }
    }
    .ant-modal-close-x {
      height: 50px;
      svg {
        fill: var(--grey-200);
      }
    }
    .ant-modal-body {
      padding: 0 16px !important;
      background: var(--theme-1700);
      color: var(--grey-200);
      font-size: 14px;
      line-height: 17.5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tabs {
        border-radius: 3px;
        height: 37.8px;
        .tab {
          height: 28.8px;
        }
      }
    }
  }
  .ant-space {
    width: 100%;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .ant-collapse {
    background-color: transparent;
  }
  .ant-collapse-item {
    border-bottom: none !important;
    background: var(--theme-1000);
  }
  .ant-collapse-header {
    background: var(--grey-500);
    font-weight: 900;
    color: #fff !important;
    height: 48px;
    padding: 8px 16px !important;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    &[aria-expanded='true'] {
      .collapse-header {
        svg {
          &:last-child {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  .ant-collapse-arrow {
    display: none;
  }
  .ant-collapse-content-box {
    background: var(--theme-1000);
  }
}
</style>

<style lang="scss" scoped>
.vip-container {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  margin-top: 16px;
  width: 280px;
}
.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 12.8px;
    height: 12.8px;
  }
}
.mt8 {
  margin-top: 8px;
}
.bold {
  font-weight: 700;
}
.progress {
  width: 100%;
  height: 1em;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  overflow: hidden;
  background: var(--theme-600);
  position: relative;
  .progress-active {
    width: 100%;
    height: 1em;
    transform: scale3d(0.89, 1, 1);
    transform-origin: 0 0;
    background: var(--green-400);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
.desc {
  font-family: zonapro-thin, sans-serif;
  margin-top: 16px;
}
.notice {
  margin: 24px 8px;
  border: 2px dotted;
  padding: 8px 8px;
  border-radius: 4px;
}
.bane {
  margin-top: 8px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
}
.bane-container {
  width: 100%;
  margin: 0 8px 24px;
  background: var(--grey-600) !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  vertical-align: top;
  padding: 16px;
}
.menu {
  font-family: zonapro-thin, sans-serif;
  border-bottom: 1px solid var(--grey-400);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  .right {
    display: flex;
    align-items: center;
  }
  svg {
    width: 16px;
    height: 16px;
  }
}
.bene {
  border-bottom: none;
}
.collapse-header {
  height: 100%;
  display: flex;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    transition: transform 0.5s ease;
    &:last-child {
      transform: rotate(180deg);
    }
  }
}
.bene-collapse {
  color: #fff;
  color: var(--grey-200);
}
</style>
