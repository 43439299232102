<template>
  <div class="login-modal">
    <a-modal
        class="login-modal"
        :centered="true"
        :closable="false"
        :footer="null"
        :visible="isLoginModal"
        :zIndex="6000"
        @cancel="closeLoginModal(false)"
    >
      <img
          class="close"
          src="@/assets/images/close.png"
          @click="closeLoginModal(false)"
      />
      <div class="login-wraper" @click="closeLoginModal(false)">
        <div class="login-container" @click.stop>
          <div class="banner">
            <img
                src="../assets/images/bg.png"
                loading="lazy"
                decoding="async"
                draggable="false"
            />
          </div>
          <div class="mobile-banner">
            <img :src="loginBgIcon" />
          </div>
          <div class="content">
            <WTabs
                class="tabs"
                :tabStyle="{
                flex: 1,
                background: 'transparent',
                justifyContent: 'center',
              }"
                :tabActiveStyle="{
                flex: 1,
                background: 'var(--theme-green)',
                justifyContent: 'center',
              }"
                :tabs="tabs"
                :activeTab="activeTab"
                @changeActive="changeActive"
            ></WTabs>
            <div class="divider"></div>

            <div class="login-tab" v-if="activeTab === 'Login'">
              <a-input
                  class="input"
                  type="number"
                  v-model="login.username"
                  :placeholder="$t('login.userName')"
              />
              <a-input-password v-model="login.password" class="input-password" :placeholder="$t('login.password')" />
              <div class="input-verify">
                <a-input v-model="login.captcha" :placeholder="$t('login.captcha')" />
                <img
                    data-v-431dac73=""
                    :src="imgCodeUrl"
                    @click="getImgCode"
                    height="70%"
                    width="120px"
                    class="cursor-pointer"
                />
              </div>

              <WButton class="login-button" @click.native="joinWinPix">
                {{ $t('login.btn') }}
              </WButton>

              <!--              <span class="forgot">Forgot password</span>-->
            </div>
            <div class="signup-tab" v-else>
              <a-input class="input-phone"
                       v-model="registrar.username"
                       type="number"
                       :placeholder="$t('registrar.userName')">
                <span slot="prefix"> +55 </span>
              </a-input>
              <a-input-password
                  v-model="registrar.password"
                  class="input-password"
                  :placeholder="$t('registrar.password')" />
              <a-input class="input"
                       v-model="registrar.inviteCode"
                       :disabled="true"
                       :placeholder="$t('registrar.inviteCode')" />
              <div class="input-verify">
                <a-input
                    v-model="registrar.captcha"
                    :placeholder="$t('registrar.captcha')" />
                <img
                    data-v-431dac73=""
                    :src="imgCodeUrl"
                    @click="getImgCode"
                    height="70%"
                    width="120px"
                    class="cursor-pointer"
                />
              </div>

              <WButton class="login-button" @click.native="registrarSubmit"> {{ $t('registrar.btn') }} </WButton>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import WTabs from '@/components/WTabs'
import WButton from '@/components/WButton'
import cookies from "@/JS/util.cookies"
import { v4 as uuidv4 } from 'uuid'

const winLogin = {
  components: {
    WTabs,
    WButton,
  },
  props: {
    showLoginModal: {
      type: Boolean,
    },
    showActiveTab: {
      type: String,
      default: 'Login'
    },
    inviteCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabs: [
        { label: 'Login', value: 'Login' },
        { label: 'Registrar', value: 'Registrar' },
      ],
      activeTab: this.showActiveTab,
      isLoginModal: false,
      isOutOpen: false, // 是否外部打开
      imgCodeId: '',
      imgCodeUrl: '',
      loginBgIcon: '',
      login: {
        username: '',
        password: '',
        captcha: ''
      },
      registrar: {
        username: '',
        password: '',
        inviteCode: '',
        captcha: '',
        email: '',
        zone: 55,
        izMail: 0
      },
    }
  },
  watch: {
    showLoginModal(newVal) {
      if(newVal) this.getImgCode();
      this.isLoginModal = (newVal == null ? {} : newVal)
    },
    showActiveTab(newVal) {
      this.activeTab = (newVal == null ? {} : newVal)
    },
    inviteCode(newVal) {
      this.registrar.inviteCode = (newVal == null ? {} : newVal)
    }
  },
  created() {
    let shareId = cookies.get('shareId');
    console.log('login', shareId);
    if (shareId) this.registrar.inviteCode = shareId;
    this.getClientMemberIndexBaseInfo();
  },
  mounted() {
    window.openLogin = this.openLogin;
  },
  methods: {
    showTopTips(code) {
      this.$message.error(this.$t(`interface.${code}`));
    },
    getClientMemberIndexBaseInfo() {
      this.$api.clientMemberIndexBaseInfo()
      .then(res => {
        if (res.success) {
          let data = res.result;
          if (data.activity.length > 0) {
            this.loginBgIcon = data.activity[0].banner;
          }
        } else {
          this.$message.error('Erro do sistema.Tente novamente mais tarde.');
        }
      })
    },
    getImgCode() {
      let id = uuidv4();
      this.imgCodeId = id;
      this.$api.getClientMemberRandomImage(this.imgCodeId)
      .then(res => {
        this.imgCodeUrl = res.result;
      }).catch(err => {
        this.$message.error('Erro do sistema.Tente novamente mais tarde.');
      });
    },
    joinWinPix() {
      if (!this.login.username) return this.$message.info('O nome de login não pode estar vazio');
      if (!this.login.password) return this.$message.info('A senha de login não pode estar vazia');
      if (!this.login.captcha) return this.$message.info('CAPTCHA não pode estar vazio');
      this.login = {
        ...this.login,
        checkKey: this.imgCodeId
      };
      this.$api.loginClientMemberLogin(this.login)
      .then(res => {
        if (res.success === true) {
          this.$store.commit('setUserUid', res.result.member.id);
          this.$store.commit('setUserInfo', res.result.member);
          cookies.set('token', res.result.token);
          this.postVipUserInfo(res.result.member.id);
        } else {
          this.getImgCode();
          this.showTopTips(res.code);
        }
      }).catch(err => {
        if (err.code == 500) {
          this.$message.error('Este usuário não existe, registre-se');
        } else {
          this.showTopTips(err.code);
        }
        this.getImgCode();
      });
    },
    registrarSubmit() {
      if (!this.registrar.username) return this.$message.info('O telefone não pode estar vazio');
      if (this.registrar.username.length != 11) return this.$message.info('O número de telefone deve ter 11 dígitos.');
      if (!this.registrar.password) return this.$message.info('A senha não pode estar em branco');
      if (!this.registrar.captcha) return this.$message.info('CAPTCHA não pode estar vazio');
      this.registrar = {
        ...this.registrar,
        checkKey: this.imgCodeId
      };
      this.$api.addClientMemberRegister(this.registrar)
      .then(res => {
        if (res.success === true) {
          this.$store.commit('setUserUid', res.result.member.id);
          cookies.set('token', res.result.token);
          this.$store.commit('setUserInfo', res.result.member);
          this.postVipUserInfo(res.result.member.id);
        } else{
          this.showTopTips(res.code);
          this.getImgCode();
        }
      }).catch(err => {
        this.showTopTips(err.code);
        this.getImgCode();
      });
    },
    postVipUserInfo(id) {
      this.$api.getVipUserInfo({id: id})
      .then(res => {
        if (res.success === true) {
          this.$store.commit('setUserInfo', res.result.memberInfo);
          this.$store.commit('setUserBalance', res.result.balance.balance.toFixed(2));
          this.closeLoginModal(false);
          this.$emit('setLogin', true);
          setTimeout(() => {
            this.util.openNotification('Faça login com sucesso, seja bem-vindo a se juntar a nós!');
          }, 250);
          // window.location.reload([true]);
        }
      }).catch(err => {
        this.$message.error('Erro do sistema.Tente novamente mais tarde.');
      });
    },
    changeActive(value) {
      this.activeTab = value
    },
    openLogin(data) {
      this.isOutOpen = data.isOutOpen;
      this.$nextTick(() => {
        this.$set(this, 'isLoginModal', true);
        this.getImgCode();
      })
    },
    closeLoginModal(bol) {
      if (this.isOutOpen) this.isLoginModal = false;
      this.$emit('setShowLoginModal', bol);
    },
    handleLogin() {
      if (this.isOutOpen) this.isLoginModal = false;
      this.$emit('setLogin', true)
      this.$emit('setShowLoginModal', false)
    },
  },
}
export default winLogin
</script>

<style lang="scss">
.login-modal {
  .ant-modal {
    width: auto !important;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-content {
    background: transparent;
    border-radius: 8px !important;
  }
}
.input-password {
  display: unset !important;
  .ant-input {
    height: 50px !important;
    width: 100% !important;
    margin-bottom: 8px !important;
    margin-top: 0rem !important ;
    border-radius: 8px !important;
    color: #fff !important ;
    font-size: 14px !important;
    background: var(--theme-1700) !important;
    border: 1px solid rgba(16, 23, 29, 0) !important;
    transition: border-color 0.15s ease !important;
    color: #fff !important;
    padding: 14px 20px 14px 32px !important;
    flex: 1 1 0% !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-overflow: unset !important;
    &:focus {
      box-shadow: none;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    color: var(--grey-200);
  }
}
.input-verify {
  margin-bottom: 8px !important;
  margin-top: 0rem !important ;
  border-radius: 8px !important;
  background: var(--theme-1700) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-input {
    height: 50px !important;
    width: 100% !important;
    color: #fff !important ;
    font-size: 14px !important;
    background: var(--theme-1700) !important;
    border: 1px solid rgba(16, 23, 29, 0) !important;
    transition: border-color 0.15s ease !important;
    color: #fff !important;
    padding: 14px 20px 14px 32px !important;
    flex: 1 1 0% !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-overflow: unset !important;
    &:focus {
      box-shadow: none;
    }
  }
  img {
    width: 120px;
    height: 39px;
  }
}
.input-phone {
  margin-bottom: 8px !important;
  margin-top: 0rem !important ;
  border-radius: 8px !important;
  background: var(--theme-1700) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-input-prefix {
    width: 80px;
    height: 50px;
    color: #fff !important;
    left: 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-input {
    margin-left: 20%;
    height: 50px !important;
    width: 80% !important;
    color: #fff !important ;
    font-size: 14px !important;
    background: var(--theme-1700) !important;
    border: 1px solid rgba(16, 23, 29, 0) !important;
    transition: border-color 0.15s ease !important;
    color: #fff !important;
    padding: 14px 20px 14px 32px !important;
    flex: 1 1 0% !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    text-overflow: unset !important;
    &:focus {
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 1028px) {
  .ant-modal-wrap {
    overflow: hidden !important;
  }
  .login-modal .close {
    position: fixed !important;
    right: 5px !important;
    top: 5px !important;
    z-index: 10 !important;
  }
  .login-modal .tab {
    height: 30px !important;
  }
}
</style>

<style lang="scss" scoped>
.close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -28px;
  top: -28px;
}
.login-container {
  width: 600px;
  height: 510px;
  position: relative;
  box-shadow: 0 0 200px var(--theme-green);
  background: radial-gradient(
          100% 200% at 0% 0%,
          var(--theme-2000),
          var(--theme-2100)
  );
  background: var(--theme-header);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  .banner {
    width: 250px;
    background: linear-gradient(135deg, #01dcba, #7f30cb);
    border-radius: 8px 125px 0 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    img {
      border: 0;
      display: block;
      outline: none;
      -webkit-user-drag: none;
    }
  }
  .mobile-banner {
    display: none;
  }
  .content {
    padding: 30px 16px;
    width: 380px;
    .tabs {
      width: 100%;
      background: var(--theme-600);
    }
    .divider {
      background: var(--theme-1100);
      height: 1px;
      margin: 16px 0;
      opacity: 0.2;
    }
    .input {
      height: 50px;
      width: 100%;
      margin-bottom: 8px;
      margin-top: 0rem !important;
      border-radius: 8px;
      color: #fff !important;
      font-size: 14px;
      background: var(--theme-1700);
      border: 1px solid rgba(16, 23, 29, 0);
      transition: border-color 0.15s ease;
      color: #fff;
      padding: 14px 20px 14px 32px;
      flex: 1 1 0%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-overflow: unset;
      &:focus {
        box-shadow: none;
      }
    }
    .login-button {
      width: 100%;
      height: 58px !important;
      margin-left: 0 !important;
      margin-bottom: 8px;
      color: #fff;
      background-color: var(--theme-green);
      height: 3.625rem;
      border-radius: var(--border-radius);
      font-weight: 700;
    }
    .forgot {
      margin-bottom: 8px;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1028px) {
  .login-wraper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .login-container {
    width: 100% !important;
    padding: 8px;
    padding-top: 48px;
    width: 600px;
    height: auto;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    .banner {
      display: none;
    }
    .mobile-banner {
      display: block;
      height: 200px;
      width: 100%;
      background: linear-gradient(135deg, #01dcba, #7f30cb);
      border-radius: 16px;
      overflow: hidden;
      img {
        height: 200px;
        width: 100%;
      }
    }
    .tabs {
      height: 40px;
      margin-top: 35px;
    }
    .content {
      width: 100%;
      padding: 0;
    }
  }
}
</style>
