<template>
  <div class="nav" @touchmove.stop @scroll.stop @click.stop="close">
    <div class="content" ref="content">
      <div class="mobile-header">
        <img class="logo" :src="`${imgUrl}${siteLogo}`" />
        <img
          class="close"
          src="@/assets/images/close.png"
          alt=""
          @click.stop="close"
        />
      </div>
      <div class="container" @click.stop>
        <div class="mobile-operation">
          <div v-if="uid">
            <div class="login logout" @click="logoutOut">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="@/assets/bcfun.svg#icon_Logout"></use>
              </svg>
              {{ $t('logout') }}
            </div>
          </div>
          <div v-else>
            <div class="login" @click="loginUser('Login')">{{ $t('login.btn') }}</div>
            <div class="sign" @click="loginUser('Registrar')">{{ $t('signUp') }}</div>
          </div>
<!--          <q-select-->
<!--              behavior="menu"-->
<!--              standout-->
<!--              hide-bottom-space-->
<!--              v-model="language"-->
<!--              :options="options"-->
<!--              class="select-language"-->
<!--              popup-content-class="klklsdfja"-->
<!--              options-selected-class="text-deep"-->
<!--          >-->
<!--            <template v-slot:prepend>-->
<!--              <span class="language-icon" :class="language.icon"></span>-->
<!--            </template>-->
<!--            <template v-slot:option="scope">-->
<!--              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">-->
<!--                <q-item-section>-->
<!--                  <span class="language-icon" :class="scope.opt.icon"></span>-->
<!--                </q-item-section>-->
<!--                <q-item-section>-->
<!--                  <q-item-label caption> {{ scope.opt.label }}</q-item-label>-->
<!--                </q-item-section>-->
<!--              </q-item>-->
<!--            </template>-->
<!--          </q-select>-->
        </div>
        <div class="section">
          <div class="title">{{ $t('winNav.navigation') }}</div>
          <div class="item" @click="topPage('/casino')">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Casino"></use>
              </svg>
            </span>
            {{ $t('column.casino') }}
          </div>
          <div class="item" @click="topPage('/favorites')">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Favorites"></use>
              </svg>
            </span>
            {{ $t('column.favourites') }}
          </div>
          <div class="item" @click="topPage('/recent')">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Recent"></use>
              </svg>
            </span>
            {{ $t('column.recent') }}
          </div>
          <div class="item" @click="topPage()">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/icons.svg#icon-trophy"></use>
              </svg>
            </span>
            {{ $t('column.vip') }}
          </div>
          <div class="item" @click="topPage('/convidar')">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Affiliate"></use>
              </svg>
            </span>
            {{ $t('column.convidar') }}
          </div>
          <div class="item" @click="topPage('/promotions')">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Promotion"></use>
              </svg>
            </span>
            {{ $t('column.gift') }}
          </div>
        </div>
        <div class="section">
          <div class="title">{{ $t('winNav.casino') }}</div>
          <div class="item color" @click="toGamePage('/casino')">
            <span class="icon">
              <svg
                data-v-5042a761=""
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                class="css-1yeuvvk"
              >
                <path
                  data-v-5042a761=""
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 10.4854C3 9.78864 3.36259 9.14212 3.9571 8.7788L11.4785 4.18236C11.7987 3.98674 12.2013 3.98674 12.5215 4.18236L20.0429 8.77881C20.6374 9.14212 21 9.78864 21 10.4854V19.3637C21 20.4683 20.1046 21.3637 19 21.3637H5C3.89543 21.3637 3 20.4683 3 19.3637V10.4854Z"
                  fill="url(#paint0_linear_917:5333)"
                ></path>
                <rect
                  data-v-5042a761=""
                  x="9"
                  y="13.3638"
                  width="6"
                  height="5"
                  rx="1"
                  fill="#4B5568"
                ></rect>
                <defs data-v-5042a761="">
                  <linearGradient
                    data-v-5042a761=""
                    id="paint0_linear_917:5333"
                    x1="12"
                    y1="4.03564"
                    x2="12"
                    y2="21.3638"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop data-v-5042a761="" stop-color="#C8D3E8"></stop>
                    <stop
                      data-v-5042a761=""
                      offset="1"
                      stop-color="#8592A9"
                    ></stop>
                  </linearGradient>
                </defs>
              </svg> </span>
            {{ $t('column.home') }}
          </div>
<!--          <div class="item color">-->
<!--            <span class="icon">-->
<!--              <svg viewBox="0 0 24 24" focusable="false">-->
<!--                <use-->
<!--                  xlink:href="../assets/icons.svg#icon-popular"-->
<!--                ></use></svg></span-->
<!--            >Popular-->
<!--          </div>-->
          <div class="item color" @click="toGamePage('/casino/group', { gameName: 'In-House', folders: 'win100_originals' })">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use
                  xlink:href="../assets/icons.svg#icon-provider-stake-originals"
                ></use>
              </svg>
            </span>
            In-House
          </div>
          <div class="item color" @click="toGamePage('/casino/group', { gameName: 'Slots', folders: 'slots' })">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/icons.svg#icon-provider-slots"></use>
              </svg> </span>
            {{ $t('column.slots') }}
          </div>
          <div class="item color" @click="toGamePage('/casino/group', { gameName: 'Live Casino', folders: 'live_casino' })">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use
                  xlink:href="../assets/icons.svg#icon-provider-live-dealers"
                ></use>
              </svg> </span>
            {{ $t('column.liveCasino') }}
          </div>
          <div class="item color" @click="toGamePage('/casino/group', { gameName: 'PG Slots', folders: 'pg_slots' })">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/icons.svg#icon-provider-slots"></use>
              </svg> </span>
            {{ $t('column.pGSlots') }}
          </div>
          <div class="item color" @click="toGamePage('/casino/group', { gameName: 'Fish', folders: 'fish' })">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/icons.svg#icon-provider-fish"></use>
              </svg> </span>
            {{ $t('column.fish') }}
          </div>
        </div>
        <div class="section">
          <div class="title">Other</div>
          <div class="item" v-for="(data, index) in serverList" :key="index" @click="openServerLink(data.link)">
            <span class="icon">
              <svg viewBox="0 0 24 24" focusable="false" v-if="data.icon === 'tg'">
<!--                <use xlink:href="../assets/yebet.svg#icon-whatsapp"></use>-->
                 <use xlink:href="../assets/yebet.svg#icon-telegram"></use>
              </svg>
              <img v-if="data.icon === 'fb'" src="@/assets/images/icon-facebook.svg" />
              <img v-if="data.icon === 'tt'" src="@/assets/images/icon-twitter.svg"/>
              <img v-if="data.icon === 'wc'" src="@/assets/images/icon-whatsapp.svg"/>
               <img v-if="data.icon === 'ins'" src="@/assets/images/ins-icon.png"/>
            </span>
            {{data.name}}
          </div>
        </div>
      </div>
    </div>

    <log-out
        :showLogoutModal="showLogoutConfirm"
        @setLogin="handleLogout"
        @setCloseLogOutModal="setCloseLogOutModal">
    </log-out>
  </div>
</template>

<script>
// import WIcon from '@/components/WIcon'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    // WIcon,
  },
  props: {
    login: {
      type: Boolean,
    },
  },
  data() {
    return {
      isMobile: false,
      serverList: [],
      showLogoutConfirm: false,
      language: {
        label: 'Español',
        value: 'mxg',
        icon: 'mxg',
      },
      options: [
        {
          label: 'English',
          value: 'English',
          icon: 'en',
        },
        {
          label: 'Nigeria',
          value: 'ngn',
          icon: 'ngn',
        },
        {
          label: 'Español',
          value: 'mxg',
          icon: 'mxg',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      uid: state => state.uid,
      siteLogo: state => state.siteLogo,
      imgUrl: state => state.imgUrl,
      telegramList: state => state.telegramList,
    }),
  },
  watch: {
    telegramList(newValue) {
      newValue == null ? false : this.getTelegramList();
    },
  },
  created() {
    if (this.telegramList) this.getTelegramList();
  },
  methods: {
    getTelegramList() {
      this.serverList = [];
      console.log(this.telegramList)
      let teList = this.telegramList;
      for (let i = 0; i < teList.length; i++) {
        this.serverList.push({ icon: teList[i].type, name: teList[i].name, link: teList[i].url });
      }
    },
    logoutOut() {
      this.close();
      this.showLogoutConfirm = true;
    },
    handleLogout() {
      this.showLogoutConfirm = false
      this.$emit('setLogin', false)
    },
    loginUser(type) {
      this.$emit('setShowLoginModal', true, type);
      this.close();
    },
    setCloseLogOutModal(bol) {
      this.showLogoutConfirm = bol
    },
    topPage(link) {
      const { uid } = this;
      if (!uid) {
        if (link === '/casino') {
          this.$router.push({
            path: link
          })
        } else {
          this.$emit('setShowLoginModal', true);
        }
      } else {
        if (link) {
          this.$router.push({
            path: link
          })
        } else {
          this.$emit('setShowVIPModal', true)
        }
      }
      if (this.isMobile) this.close();
    },
    openServerLink(link) {
      const { isMobile } = this;
      if (isMobile) {
        window.location.href = link;
      } else {
        window.open(link);
      }
      if (isMobile) this.close();
    },
    toGamePage(link, params) {
      if (params) {
        window.location.href = link + '?gameName=' + params.gameName + '&folders=' + params.folders;
      } else {
        this.$router.push({
          path: link,
        })
      }
      if (this.isMobile) this.close();
    },
    close() {
      this.$emit('setShow', false)
    },
    handleResize() {
      const isMobile = window.innerWidth < 1028
      this.isMobile = isMobile
      if (isMobile) {
        this.$refs.content.style.width = 0.88 * window.innerWidth + 'px'
      } else {
        this.$emit('setShow', true)
        this.$refs.content.style.width = '100%'
      }
    },
  },
  mounted() {
    const isMobile = window.innerWidth < 1028
    this.isMobile = isMobile
    if (isMobile) {
      this.$refs.content.style.width = 0.88 * window.innerWidth + 'px'
    } else {
      this.$emit('setShow', true)
      this.$refs.content.style.width = '100%'
    }
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss">
.select-language {
  .q-field__before,
  .q-field__prepend {
    padding-right: 4px;
  }
}
.klklsdfja {
  .q-item {
    color: #fff;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--theme-2000);
    &:hover {
      background: var(--theme-green);
      opacity: 0.8;
    }
  }
  .text-deep {
    background: var(--theme-green) !important;
  }
  .q-item__label--caption {
    color: #fff;
  }
  .q-item__section--main {
    flex: none;
  }
  .language-icon {
    margin-right: 11px;
    width: 18.66px;
    height: 14px;
    line-height: 14px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNDgwIj48cGF0aCBmaWxsPSIjMDEyMTY5IiBkPSJNMCAwaDY0MHY0ODBIMHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNzUgMGwyNDQgMTgxTDU2MiAwaDc4djYyTDQwMCAyNDFsMjQwIDE3OHY2MWgtODBMMzIwIDMwMSA4MSA0ODBIMHYtNjBsMjM5LTE3OEwwIDY0VjBoNzV6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTQyNCAyODFsMjE2IDE1OXY0MEwzNjkgMjgxaDU1em0tMTg0IDIwbDYgMzVMNTQgNDgwSDBsMjQwLTE3OXpNNjQwIDB2M0wzOTEgMTkxbDItNDRMNTkwIDBoNTB6TTAgMGwyMzkgMTc2aC02MEwwIDQyVjB6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTI0MSAwdjQ4MGgxNjBWMEgyNDF6TTAgMTYwdjE2MGg2NDBWMTYwSDB6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTAgMTkzdjk2aDY0MHYtOTZIMHpNMjczIDB2NDgwaDk2VjBoLTk2eiIvPjwvc3ZnPg==);
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    &.p {
      background-image: url('@/assets/images/br.svg');
    }
    &.mxg {
      background-image: url('@/assets/images/mx.svg');
    }
    &.ngn {
      background-image: url("@/assets/images/ngn.png");
    }
    &.m {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+PHBhdGggZmlsbD0iI2Y5MyIgZD0iTTAgMGg2NDB2MTYwSDB6Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMTYwaDY0MHYxNjBIMHoiLz48cGF0aCBmaWxsPSIjMTI4ODA3IiBkPSJNMCAzMjBoNjQwdjE2MEgweiIvPjxnIHRyYW5zZm9ybT0ibWF0cml4KDMuMiAwIDAgMy4yIDMyMCAyNDApIj48Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+PGNpcmNsZSByPSIxNy41IiBmaWxsPSIjZmZmIi8+PGNpcmNsZSByPSIzLjUiIGZpbGw9IiMwMDgiLz48ZyBpZD0iZCI+PGcgaWQ9ImMiPjxnIGlkPSJiIj48ZyBpZD0iYSIgZmlsbD0iIzAwOCI+PGNpcmNsZSByPSIuOSIgdHJhbnNmb3JtPSJyb3RhdGUoNy41IC04LjggMTMzLjUpIi8+PHBhdGggZD0iTTAgMTcuNUwuNiA3IDAgMmwtLjYgNUwwIDE3LjV6Ii8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTUpIiB4bGluazpocmVmPSIjYSIvPjwvZz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKDMwKSIgeGxpbms6aHJlZj0iI2IiLz48L2c+PHVzZSB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSg2MCkiIHhsaW5rOmhyZWY9IiNjIi8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwKSIgeGxpbms6aHJlZj0iI2QiLz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKC0xMjApIiB4bGluazpocmVmPSIjZCIvPjwvZz48L3N2Zz4=);
    }
  }
}
.nav {
  .q-field__native {
    color: var(--theme-1100) !important;
  }
  .q-icon {
    color: var(--theme-1100);
  }
  .q-field--standout.q-field--highlighted .q-field__control {
    box-shadow: inherit;
    background: inherit;
  }
}
</style>

<style lang="scss" scoped>
.nav {
  display: block;
  width: 260px;
  top: 75px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3000;
  .content {
    padding-top: 17px;
    width: 100%;
    height: 100%;
    will-change: scroll-position;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;

    /* 自定义滚动条样式 */
    scrollbar-width: thin; /* 滚动条宽度 */
    scrollbar-color: #333 #666; /* 滚动条颜色 */

    overflow: hidden; /* 初始状态下隐藏滚动条 */

    &:hover,
    &::-webkit-scrollbar-thumb {
      overflow-y: scroll; /* 鼠标悬停或滚动时显示滚动条 */
    }
    &::-webkit-scrollbar {
      width: 10px; /* 修改滚动条宽度 */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.211262); /* 滚动条滑块颜色 */
      // opacity: 0.211262;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0); /* 滚动条轨道颜色 */
    }

    .mobile-header {
      display: none;
    }
    .container {
      padding: 0 5px 0 0;

      .mobile-operation {
        display: none;
      }
      .section {
        padding-left: 32px;
        overflow: hidden;
        .title {
          font-family: zonapro-thin, sans-serif;
          margin-top: 16px;
          margin-bottom: 25px;
          color: #fff;
          font-size: 12px;
          line-height: 13.2px;
          opacity: 0.5;
          text-transform: uppercase;
          letter-spacing: 1.2px;
        }
        .item {
          margin-bottom: 24px;
          color: #fff;
          cursor: pointer;
          user-select: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.5px;
          display: flex;
          align-items: center;
          &:hover {
            color: var(--theme-green) !important;
          }
          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: currentColor;
            svg {
              width: 20px;
              height: 20px;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        .color {
          color: var(--theme-1100);
        }
      }
    }
  }
}
@media screen and (max-width: 1028px) {
  .nav {
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    overflow: hidden;

    .content {
      padding: 0;
      width: 330px;
      background: radial-gradient(
              100% 200% at 0 0,
              var(--theme-2000) 0,
              var(--theme-1700) 100%
      );
      transition: transform 0.3s;

      &::-webkit-scrollbar {
        display: none;
      }

      .mobile-header {
        display: block;
        padding: 0 10px;
        height: 75px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          margin-left: 8px;
          width: 70px;
          height: 70px;
        }
        .close {
          margin-right: 25px;
          width: 25px;
          height: 25px;
        }
      }
      .container {
        flex: 1;
        overflow: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .section {
          padding-left: 16px;
          .title {
            margin-bottom: 20px;
          }
        }
        .mobile-operation {
          display: flex;
          margin: 0 16px;

          .select-language {
            margin-left: 8px;
            height: 40px;
            width: 40%;
            border-radius: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            background: var(--theme-100);
            color: var(--theme-1100) !important;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            position: relative;
            font-size: 14px;
            line-height: 1.715em;
            text-decoration: none;
            font-weight: 500;
            text-align: center;
            .language-icon {
              width: 18.66px;
              height: 14px;
              line-height: 14px;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNDgwIj48cGF0aCBmaWxsPSIjMDEyMTY5IiBkPSJNMCAwaDY0MHY0ODBIMHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNzUgMGwyNDQgMTgxTDU2MiAwaDc4djYyTDQwMCAyNDFsMjQwIDE3OHY2MWgtODBMMzIwIDMwMSA4MSA0ODBIMHYtNjBsMjM5LTE3OEwwIDY0VjBoNzV6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTQyNCAyODFsMjE2IDE1OXY0MEwzNjkgMjgxaDU1em0tMTg0IDIwbDYgMzVMNTQgNDgwSDBsMjQwLTE3OXpNNjQwIDB2M0wzOTEgMTkxbDItNDRMNTkwIDBoNTB6TTAgMGwyMzkgMTc2aC02MEwwIDQyVjB6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTI0MSAwdjQ4MGgxNjBWMEgyNDF6TTAgMTYwdjE2MGg2NDBWMTYwSDB6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTAgMTkzdjk2aDY0MHYtOTZIMHpNMjczIDB2NDgwaDk2VjBoLTk2eiIvPjwvc3ZnPg==);
              background-size: contain;
              background-position: 50%;
              background-repeat: no-repeat;
              &.p {
                background-image: url('@/assets/images/br.svg');
              }
              &.mxg {
                background-image: url('@/assets/images/mx.svg');
              }
              &.ngn {
                background-image: url("@/assets/images/ngn.png");
              }
              &.m {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+PHBhdGggZmlsbD0iI2Y5MyIgZD0iTTAgMGg2NDB2MTYwSDB6Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMTYwaDY0MHYxNjBIMHoiLz48cGF0aCBmaWxsPSIjMTI4ODA3IiBkPSJNMCAzMjBoNjQwdjE2MEgweiIvPjxnIHRyYW5zZm9ybT0ibWF0cml4KDMuMiAwIDAgMy4yIDMyMCAyNDApIj48Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+PGNpcmNsZSByPSIxNy41IiBmaWxsPSIjZmZmIi8+PGNpcmNsZSByPSIzLjUiIGZpbGw9IiMwMDgiLz48ZyBpZD0iZCI+PGcgaWQ9ImMiPjxnIGlkPSJiIj48ZyBpZD0iYSIgZmlsbD0iIzAwOCI+PGNpcmNsZSByPSIuOSIgdHJhbnNmb3JtPSJyb3RhdGUoNy41IC04LjggMTMzLjUpIi8+PHBhdGggZD0iTTAgMTcuNUwuNiA3IDAgMmwtLjYgNUwwIDE3LjV6Ii8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTUpIiB4bGluazpocmVmPSIjYSIvPjwvZz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKDMwKSIgeGxpbms6aHJlZj0iI2IiLz48L2c+PHVzZSB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSg2MCkiIHhsaW5rOmhyZWY9IiNjIi8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwKSIgeGxpbms6aHJlZj0iI2QiLz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKC0xMjApIiB4bGluazpocmVmPSIjZCIvPjwvZz48L3N2Zz4=);
              }
            }
          }
          .login {
            text-transform: none;
            color: var(--theme-green);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: background-color 0.15s ease, border-color 0.15s ease,
            color 0.15s ease;
            font-size: 14px;
            font-weight: 700;
            position: relative;
            padding: 0 16px;
            overflow: hidden;
            white-space: nowrap;
            height: 38px;
            max-width: 100%;
            cursor: pointer;
            user-select: none;
            border: 1px solid currentColor;
            font-size: 12px !important;
            padding: 0 12px;
            &:hover {
              background: rgba(255, 255, 255, 0.1);
            }
            &.logout {
              svg {
                margin-right: 12px;
                width: 20.58px;
                height: 20.58px;
                fill: currentColor;
              }
            }
          }
          .sign {
            margin-left: 8px;
            background: var(--theme-green);
            text-transform: none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            transition: background-color 0.15s ease, border-color 0.15s ease,
            color 0.15s ease;
            font-size: 14px;
            font-weight: 700;
            position: relative;
            padding: 0 16px;
            overflow: hidden;
            white-space: nowrap;
            height: 38px;
            max-width: 100%;
            outline: 0 !important;
            user-select: none !important;
            color: #fff !important;
            cursor: pointer;
            user-select: none;
            flex-direction: column;
            border: 0;
            vertical-align: middle;
            text-align: center;
            width: auto;
            &:hover {
              opacity: 0.9;
            }
            font-size: 12px !important;
            margin-left: 8px !important;
            padding: 0 12px;
          }
        }
      }
    }
  }
}
</style>
