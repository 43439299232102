import cookies from '@/JS/util.cookies.js';
import router from '@/router'

export default {
  setUserUid(state, uid) {
    cookies.set('userId', uid);
    state.uid = uid;
  },
  setUserInfo(state, info) {
    cookies.set('userInfo', info);
    state.userInfo = info;
  },
  setSiteLogo(state, info) {
    cookies.set('siteLogo', info);
    state.siteLogo = info;
  },
  setGameSingle(state, info) {
    cookies.set('gameSingle', info);
    state.gameSingle = info;
  },
  setUserBalance(state, info) {
    state.userBalance = info;
  },
  setSystemSetting(state, info) {
    cookies.set('systemSetting', info);
    state.systemSetting = info;
  },
  setSiteInfo(state, info) {
    cookies.set('siteInfo', info);
    state.siteInfo = info;
  },
  setTelegramList(state, info) {
    cookies.set('telegramList', info);
    state.telegramList = info;
  },
  setLogoutUser(state) {
    cookies.remove('userId');
    cookies.remove('userInfo');
    cookies.remove('token');
    cookies.remove('gameSingle');
    cookies.remove('systemSetting');
    cookies.remove('setSiteInfo');
    cookies.remove('telegramList');
    state.uid = '';
    state.userInfo = '';
    state.gameSingle = '';
    state.userBalance = '';
    state.systemSetting = '';
    state.siteInfo = '';
    state.telegramList = '';
    let pathname = window.location.pathname;
    if (pathname !== '/') router.replace({path: '/'});
  }
}
