<template>
  <header class="header">
    <div class="left">
      <div class="logo" :style="{'background-image':`url(${imgUrl}${siteLogo})`}"></div>
      <div class="link-container">
        <router-link to="/casino" class="link" tag="div"> {{ $t('column.casino') }} </router-link>
        <router-link to="/convidar" class="link" tag="div">
          {{ $t('column.convidar') }}
        </router-link>
        <router-link to="/promotions" class="link" tag="div">
          {{ $t('column.gift') }}
        </router-link>
      </div>
    </div>

    <div class="right">
<!--      <q-select-->
<!--          standout-->
<!--          hide-bottom-space-->
<!--          v-model="language"-->
<!--          :options="options"-->
<!--          class="select-language"-->
<!--          popup-content-class="klklsdfja"-->
<!--          options-selected-class="text-deep"-->
<!--      >-->
<!--        <template v-slot:prepend>-->
<!--          <span class="language-icon" :class="language.icon"></span>-->
<!--        </template>-->
<!--        <template v-slot:option="scope">-->
<!--          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">-->
<!--            <q-item-section>-->
<!--              <span class="language-icon" :class="scope.opt.icon"></span>-->
<!--            </q-item-section>-->
<!--            <q-item-section>-->
<!--              <q-item-label caption> {{ scope.opt.label }}</q-item-label>-->
<!--            </q-item-section>-->
<!--          </q-item>-->
<!--        </template>-->
<!--      </q-select>-->

      <div class="right" v-if="uid">
        <WButton class="money">
          <svg viewBox="0 0 24 24" focusable="false">
            <use xlink:href="@/assets/icons.svg#icon-currency-brl"></use>
          </svg>
          <div style="display: flex;align-items: center">
            {{ userBalance }}
            <heavy-load></heavy-load>
          </div>
          <div class="adsdkf mobile">
            <a-tooltip
                :visible="true"
                placement="top"
                color="rgb(255, 198, 74)"
                :auto-adjust-overflow="false"
                :get-popup-container="getPopupContainer"
            >
              <template #title> 150% {{ $t('column.bouns') }} </template>
              <svg
                  @click="$router.push('/pages/wallet/index')"
                  viewBox="0 0 24 24"
                  focusable="false"
              >
                <use xlink:href="@/assets/vave.svg#add-circle"></use>
              </svg>
            </a-tooltip>
          </div>
        </WButton>
        <a-dropdown :get-popup-container="getPopupContainer" :trigger="[isMobile ? 'click' : 'hover']">
          <div class="user">
            <img src="@/assets/images/avatar.png" />
            <div class="pc" v-if="userInfo">+{{ $t('zone') }}{{ userInfo.username }}</div>
          </div>
          <template #overlay>
            <div class="user-menu">
              <div class="user-info">
                <img src="@/assets/images/avatar.png" />
                <div class="uid">UID:{{ uid }}</div>
                <div class="copy"  @click="copyInvitCode">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-copy"></use>
                  </svg>
                </div>
              </div>
              <div class="link-info">
                <div class="link-item" @click="$router.push('/pages/wallet/index')">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-coins"></use>
                  </svg>
                  {{ $t('column.wallet') }}
                </div>
                <div class="link-item" @click="$emit('setShowVIPModal', true)">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-trophy"></use>
                  </svg>
                  {{ $t('column.vip') }}
                </div>
                <div class="link-item" @click="$router.push('/convidar')">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/bcfun.svg#icon_Affiliate"></use>
                  </svg>
                  {{ $t('column.convidar') }}
                </div>
                <div class="link-item">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-cashback"></use>
                  </svg>
                  {{ $t('column.caseBack') }}
                </div>
                <div class="link-item" @click="$router.push('/transactions')">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-list"></use>
                  </svg>
                  {{ $t('column.tarn') }}
                </div>
                <div class="link-item">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-notification"></use>
                  </svg>
                  {{ $t('column.note') }}
                </div>
                <div class="link-item">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-gear"></use>
                  </svg>
                  {{ $t('column.setting') }}
                </div>
                <div class="link-item">
                  <svg viewBox="0 0 24 24" focusable="false">
                    <use xlink:href="@/assets/icons.svg#icon-support"></use>
                  </svg>
                  {{ $t('column.sever') }}
                </div>
              </div>

              <WButton class="layout" @click.native="showLogoutConfirm = true">
                <svg viewBox="0 0 24 24" focusable="false">
                  <use xlink:href="@/assets/bcfun.svg#icon_Logout"></use>
                </svg>
                {{ $t('logout') }}
              </WButton>
            </div>
          </template>
        </a-dropdown>

        <div class="carteira pc">
          <a-tooltip
              :visible="true"
              placement="top"
              color="rgb(255, 198, 74)"
              :auto-adjust-overflow="false"
              :get-popup-container="getPopupContainer"
          >
            <template #title> 150% {{ $t('column.bouns') }} </template>
            <WButton @click.native="$router.push('/pages/wallet/index')"> {{ $t('column.wallet') }} </WButton>
          </a-tooltip>
        </div>
        <div class="icon" @click="show">
          <svg viewBox="0 0 24 24" focusable="false">
            <use xlink:href="../assets/vave.svg#burger"></use>
          </svg>
        </div>
      </div>

      <div class="right" v-else>
        <div class="login" @click="$emit('setShowLoginModal', true, 'Login')">{{ $t('login.btn') }}</div>
        <div class="sign" @click="$emit('setShowLoginModal', true, 'Registrar')">{{ $t('signUp') }}</div>
        <div class="icon" @click="show">
          <svg viewBox="0 0 24 24" focusable="false">
            <use xlink:href="../assets/vave.svg#burger"></use>
          </svg>
        </div>
      </div>
    </div>

    <log-out
        :showLogoutModal="showLogoutConfirm"
        @setLogin="handleLogout"
        @setCloseLogOutModal="setCloseLogOutModal">
    </log-out>
  </header>
</template>

<script>
import WButton from '@/components/WButton'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    WButton,
  },
  props: {
    login: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      uid: state => state.uid,
      userInfo: state => state.userInfo,
      userBalance: state => state.userBalance,
      siteLogo: state => state.siteLogo,
      imgUrl: state => state.imgUrl
    }),
  },
  data() {
    return {
      showLogoutConfirm: false,
      isLogin: this.login,
      isMobile: false,
      language: {
        label: 'Español',
        value: 'mxg',
        icon: 'mxg',
      },
      options: [
        {
          label: 'English',
          value: 'English',
          icon: 'en',
        },
        {
          label: 'Nigeria',
          value: 'ngn',
          icon: 'ngn',
        },
        {
          label: 'Español',
          value: 'mxg',
          icon: 'mxg',
        },
      ],
    }
  },
  watch: {
    login(newVal) {
      this.isLogin = (newVal == null ? {} : newVal)
    },
  },
  methods: {
    copyInvitCode() {
      //创建input标签
      let input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.uid;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.util.openNotification('Sucesso na cópia!');
      //移除input标签
      document.body.removeChild(input)
    },
    show() {
      this.$emit('show')
    },
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    setCloseLogOutModal(bol) {
      this.showLogoutConfirm = bol
    },
    handleLogout() {
      this.showLogoutConfirm = false
      this.$emit('setLogin', false)
    },
    handleResize() {
      const isMobile = window.innerWidth < 1028
      this.isMobile = isMobile
    },
  },
  mounted() {
    const isMobile = window.innerWidth < 1028
    this.isMobile = isMobile
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss">
@keyframes twinkling-1ec4c668 {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: scale(0.8);
  }
}
.select-language {
  .q-field__before,
  .q-field__prepend {
    padding-right: 4px;
  }
}
.klklsdfja {
  .q-item {
    color: #fff;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--theme-2000);
    &:hover {
      background: var(--theme-green);
      opacity: 0.8;
    }
  }
  .text-deep {
    background: var(--theme-green) !important;
  }
  .q-item__label--caption {
    color: #fff;
  }
  .q-item__section--main {
    flex: none;
  }
  .language-icon {
    margin-right: 11px;
    width: 18.66px;
    height: 14px;
    line-height: 14px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNDgwIj48cGF0aCBmaWxsPSIjMDEyMTY5IiBkPSJNMCAwaDY0MHY0ODBIMHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNzUgMGwyNDQgMTgxTDU2MiAwaDc4djYyTDQwMCAyNDFsMjQwIDE3OHY2MWgtODBMMzIwIDMwMSA4MSA0ODBIMHYtNjBsMjM5LTE3OEwwIDY0VjBoNzV6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTQyNCAyODFsMjE2IDE1OXY0MEwzNjkgMjgxaDU1em0tMTg0IDIwbDYgMzVMNTQgNDgwSDBsMjQwLTE3OXpNNjQwIDB2M0wzOTEgMTkxbDItNDRMNTkwIDBoNTB6TTAgMGwyMzkgMTc2aC02MEwwIDQyVjB6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTI0MSAwdjQ4MGgxNjBWMEgyNDF6TTAgMTYwdjE2MGg2NDBWMTYwSDB6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTAgMTkzdjk2aDY0MHYtOTZIMHpNMjczIDB2NDgwaDk2VjBoLTk2eiIvPjwvc3ZnPg==);
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    &.p {
      background-image: url('@/assets/images/br.svg');
    }
    &.mxg {
      background-image: url('@/assets/images/mx.svg');
    }
    &.ngn {
      background-image: url("@/assets/images/ngn.png");
    }
    &.m {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+PHBhdGggZmlsbD0iI2Y5MyIgZD0iTTAgMGg2NDB2MTYwSDB6Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMTYwaDY0MHYxNjBIMHoiLz48cGF0aCBmaWxsPSIjMTI4ODA3IiBkPSJNMCAzMjBoNjQwdjE2MEgweiIvPjxnIHRyYW5zZm9ybT0ibWF0cml4KDMuMiAwIDAgMy4yIDMyMCAyNDApIj48Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+PGNpcmNsZSByPSIxNy41IiBmaWxsPSIjZmZmIi8+PGNpcmNsZSByPSIzLjUiIGZpbGw9IiMwMDgiLz48ZyBpZD0iZCI+PGcgaWQ9ImMiPjxnIGlkPSJiIj48ZyBpZD0iYSIgZmlsbD0iIzAwOCI+PGNpcmNsZSByPSIuOSIgdHJhbnNmb3JtPSJyb3RhdGUoNy41IC04LjggMTMzLjUpIi8+PHBhdGggZD0iTTAgMTcuNUwuNiA3IDAgMmwtLjYgNUwwIDE3LjV6Ii8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTUpIiB4bGluazpocmVmPSIjYSIvPjwvZz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKDMwKSIgeGxpbms6aHJlZj0iI2IiLz48L2c+PHVzZSB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSg2MCkiIHhsaW5rOmhyZWY9IiNjIi8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwKSIgeGxpbms6aHJlZj0iI2QiLz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKC0xMjApIiB4bGluazpocmVmPSIjZCIvPjwvZz48L3N2Zz4=);
    }
  }
}
.header {
  .q-field__native {
    color: var(--theme-1100) !important;
  }
  .q-icon {
    color: var(--theme-1100);
  }
  .q-field--standout.q-field--highlighted .q-field__control {
    box-shadow: inherit;
    background: inherit;
  }
  .ant-tooltip {
    transform-origin: 50% 30.525px;
    margin-top: -10px;
    animation: twinkling-1ec4c668 1s ease-in-out infinite;
    left: -3px;
    top: -5px !important;
    transform: scale(0.8);
    color: #dd244a;
  }
  .ant-tooltip-arrow {
    width: 100% !important;
  }
  .ant-tooltip-arrow::before {
    width: 10px !important;
    height: 10px !important;
    left: 15px !important;
    background-color: #ffc64a !important;
  }
  .ant-tooltip-inner {
    padding: 0 !important;
    width: auto !important;
    height: 17px !important;
    background: rgb(255, 198, 74) !important;
    color: #dd244a !important;
    white-space: nowrap !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    min-height: auto !important ;
    padding: 0 4px !important;
    border-radius: 2px !important;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48),
    0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2) !important;
    font-feature-settings: 'tnum', 'tnum';
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    text-shadow: 0 0 2px #104839;
  }
}
.logout-modal {
  .ant-modal-wrap {
    padding: 24px;
  }
  .ant-modal-content {
    max-width: 500px;
    width: 100%;
    background: var(--theme-1700) !important;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
    .ant-modal-header {
      background: var(--theme-1700);
      color: #fff;
      border: 0;
      height: 50px;
      padding: 8px;
      .ant-modal-title {
        margin-left: 16px;
        color: var(--grey-200);
      }
    }
    .ant-modal-close-x {
      height: 50px;
      svg {
        fill: var(--grey-200);
      }
    }
    .ant-modal-body {
      font-family: zonapro-thin, sans-serif;
      margin-top: 16px;
      padding: 24px !important;
      background: var(--theme-1700);
      color: var(--grey-200);
      font-size: 14px;
      line-height: 17.5px;
    }
  }
}

@media screen and (max-width: 1028px) {
  .ant-tooltip-inner {
    text-shadow: none !important;
  }

  .logout-modal {
    .ant-modal {
      width: 100% !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.mobile {
  display: none;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: 75px;
  padding: 0 32px;
  background: var(--theme-header);
}
.logo {
  width: 100px;
  height: 100px;
  cursor: pointer;
  user-select: none;
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url('@/assets/images/winiu7.png');
}
.left {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  height: 100%;
  .link-container {
    padding-left: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    .link {
      font-family: zonapro, sans-serif;
      text-decoration: none;
      display: block;
      transition: color 0.15s ease;
      color: #fff;
      cursor: pointer;
      user-select: none;
      font-weight: 700;
      padding: 0 20px;
      font-size: 14px;
      line-height: 35px;
      position: relative;
      white-space: nowrap;
      margin-right: 10px;
      &:hover {
        color: var(--theme-green);
      }
    }
  }
}
.right {
  display: flex;
  align-items: center;
  .select-language {
    margin-right: 16px;
    height: 40px;
    border-radius: 8px;
    min-width: 135px !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    background: var(--theme-100);
    color: var(--theme-1100) !important;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    position: relative;
    font-size: 14px;
    line-height: 1.715em;
    text-decoration: none;
    font-weight: 500;
    text-align: center;
    .language-icon {
      width: 18.66px;
      height: 14px;
      line-height: 14px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNDgwIj48cGF0aCBmaWxsPSIjMDEyMTY5IiBkPSJNMCAwaDY0MHY0ODBIMHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNNzUgMGwyNDQgMTgxTDU2MiAwaDc4djYyTDQwMCAyNDFsMjQwIDE3OHY2MWgtODBMMzIwIDMwMSA4MSA0ODBIMHYtNjBsMjM5LTE3OEwwIDY0VjBoNzV6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTQyNCAyODFsMjE2IDE1OXY0MEwzNjkgMjgxaDU1em0tMTg0IDIwbDYgMzVMNTQgNDgwSDBsMjQwLTE3OXpNNjQwIDB2M0wzOTEgMTkxbDItNDRMNTkwIDBoNTB6TTAgMGwyMzkgMTc2aC02MEwwIDQyVjB6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTI0MSAwdjQ4MGgxNjBWMEgyNDF6TTAgMTYwdjE2MGg2NDBWMTYwSDB6Ii8+PHBhdGggZmlsbD0iI0M4MTAyRSIgZD0iTTAgMTkzdjk2aDY0MHYtOTZIMHpNMjczIDB2NDgwaDk2VjBoLTk2eiIvPjwvc3ZnPg==);
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      &.p {
        background-image: url('@/assets/images/br.svg');
      }
      &.mxg {
        background-image: url('@/assets/images/mx.svg');
      }
      &.ngn {
        background-image: url("@/assets/images/ngn.png");
      }
      &.m {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQwIDQ4MCI+PHBhdGggZmlsbD0iI2Y5MyIgZD0iTTAgMGg2NDB2MTYwSDB6Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMTYwaDY0MHYxNjBIMHoiLz48cGF0aCBmaWxsPSIjMTI4ODA3IiBkPSJNMCAzMjBoNjQwdjE2MEgweiIvPjxnIHRyYW5zZm9ybT0ibWF0cml4KDMuMiAwIDAgMy4yIDMyMCAyNDApIj48Y2lyY2xlIHI9IjIwIiBmaWxsPSIjMDA4Ii8+PGNpcmNsZSByPSIxNy41IiBmaWxsPSIjZmZmIi8+PGNpcmNsZSByPSIzLjUiIGZpbGw9IiMwMDgiLz48ZyBpZD0iZCI+PGcgaWQ9ImMiPjxnIGlkPSJiIj48ZyBpZD0iYSIgZmlsbD0iIzAwOCI+PGNpcmNsZSByPSIuOSIgdHJhbnNmb3JtPSJyb3RhdGUoNy41IC04LjggMTMzLjUpIi8+PHBhdGggZD0iTTAgMTcuNUwuNiA3IDAgMmwtLjYgNUwwIDE3LjV6Ii8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTUpIiB4bGluazpocmVmPSIjYSIvPjwvZz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKDMwKSIgeGxpbms6aHJlZj0iI2IiLz48L2c+PHVzZSB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB0cmFuc2Zvcm09InJvdGF0ZSg2MCkiIHhsaW5rOmhyZWY9IiNjIi8+PC9nPjx1c2Ugd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwKSIgeGxpbms6aHJlZj0iI2QiLz48dXNlIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0icm90YXRlKC0xMjApIiB4bGluazpocmVmPSIjZCIvPjwvZz48L3N2Zz4=);
      }
    }
  }
  .login {
    text-transform: none;
    color: var(--theme-green);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    padding: 0 16px;
    overflow: hidden;
    white-space: nowrap;
    height: 40px;
    max-width: 100%;
    cursor: pointer;
    user-select: none;
    border: 1px solid currentColor;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .sign {
    margin-left: 24px;
    background: var(--theme-green);
    text-transform: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    padding: 0 16px;
    overflow: hidden;
    white-space: nowrap;
    height: 40px;
    max-width: 100%;
    outline: 0 !important;
    user-select: none !important;
    color: #fff !important;
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    border: 0;
    vertical-align: middle;
    text-align: center;
    width: auto;
    &:hover {
      opacity: 0.9;
    }
  }
  .money {
    background-color: var(--theme-600);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 40px;
    padding: 0 16px;
    overflow: visible;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
    .adsdkf {
      width: 32px;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 16px;
      background: var(--theme-green);
      border-radius: 0 8px 8px 0;
      svg {
        width: 20px;
        height: 20px;
        margin: 0;
        fill: #fff;
      }
    }
  }
  .user {
    font-family: zonapro, sans-serif;
    margin: 24px;
    padding: 4px 4px;
    height: 44px;
    color: #fff !important;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 7px;
    }
  }
  .carteira {
    position: relative;
    button {
      text-shadow: 0 0 2px #104839;
    }
  }
}
.user-menu {
  background-color: var(--theme-2000);
  border-radius: var(--border-radius);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.23921568627450981);
  padding: 1rem 0;
  width: 424px;
  // max-height: 237px;
  height: 300px;
  overflow: hidden;
  .user-info {
    width: 100%;
    height: 2.625rem;
    display: flex;
    align-items: center;
    padding: 0 1.125rem;
    cursor: pointer;
    img {
      width: 42px;
      height: 42px;
    }
    .uid {
      font-family: zonapro, sans-serif;
      margin-left: 0.625rem;
      margin-right: 4px;
      font-size: 1rem;
      color: #fff;
      line-height: 1.25rem;
      font-weight: 700;
    }
    .copy {
      width: 46px;
      height: 22px;
      background: var(--theme-100);
      color: #fff !important;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 14px;
        height: 14px;
        fill: #fff;
      }
    }
  }
  .link-info {
    margin: 0.625rem 1.125rem;
    padding: 0.75rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    border-radius: var(--border-radius);
    background-color: var(--theme-1800);
    .link-item {
      height: 2.25rem;
      width: 50%;
      border-radius: var(--border-radius);
      padding-left: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      &:hover {
        svg {
          fill: #fff;
        }
      }
      svg {
        margin-right: 0.875rem;
        width: 1.4em;
        height: 1.4em;
        fill: var(--theme-1100);
      }
    }
  }
  .layout {
    margin-left: 1.125rem;
    width: 119px;
    height: 36px;
    background: transparent;
    border-radius: 3px;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    svg {
      margin-right: 12px;
      width: 24px;
      height: 24px;
      fill: #fff;
    }
  }
}

.logout-button {
  width: 100%;
  margin-top: 16px;
  margin-left: 0 !important;
  height: 52px !important;
  background: var(--red-500);
  border-radius: 3px;
}

@media screen and (max-width: 1028px) {
  .pc {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .header {
    height: 75px;
  }
  .link-container,
  .select-language {
    display: none !important;
  }
  .login {
    font-size: 12px !important;
    height: 32px !important;
    padding: 0 12px;
  }
  .sign {
    font-size: 12px !important;
    margin-left: 8px !important;
    height: 32px !important;
    padding: 0 12px;
  }
  .icon {
    margin-left: 8px !important;
    width: 24px !important;
    height: 24px !important;
    color: #fff;
    fill: currentColor;
    cursor: pointer;
    user-select: none;
  }
  .right {
    .user {
      margin: 0 !important;
      margin-left: 8px !important;
      img {
        margin-right: 0 !important;
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 60px !important;
  }
}

@media screen and (max-width: 620px) {
  .user-menu {
    width: 100vw;
  }
}
</style>
