<template>
  <div class="page" @click.stop="showService = false">
    <div v-for="item in 1" :key="item" class="carousel pc">
      <div class="swiper">
        <div
          class="container"
          :style="{
            transform: `translate3d(${-1 * carouselIndex * 100.68}%, 0px, 0px)`,
          }"
        >
          <img-loading
              v-for="(data, index) in swiperList"
              :key="index"
              :loadingSrc="`${data.banner}?&dpr=1.25&format=auto&auto=format&q=50&blur=200&px=6&quality=15&h=340&w=650`"
              :src="`${data.banner}?&dpr=1.25&format=auto&auto=format&q=50&h=340&w=650`"
              @click.native="toActivityactivityNoticePage(data)"
          ></img-loading>
        </div>
      </div>

      <div class="left" @click="handleCarousel(-1)">
        <svg viewBox="0 0 24 24" focusable="false">
          <use xlink:href="../assets/bcfun.svg#icon_Arrow"></use>
        </svg>
      </div>
      <div class="right" @click="handleCarousel(1)">
        <svg viewBox="0 0 24 24" focusable="false">
          <use xlink:href="../assets/bcfun.svg#icon_Arrow"></use>
        </svg>
      </div>
      <div class="dots">
        <div class="dot active"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div v-if="swiperList.length > 0 && isMobile" class="slider-wrapper mobile">
      <WSlider>
        <div
            v-for="(data, index) in swiperList"
            :key="index"
        >
          <a @click.prevent="toActivityactivityNoticePage(data)">
<!--            <img-loading-->
<!--                v-if="data.banner"-->
<!--                :loadingSrc="`${data.banner}?&dpr=1.25&format=auto&auto=format&q=50&blur=200&px=6&quality=15&h=340&w=650`"-->
<!--                :src="`${data.banner}?&dpr=1.25&format=auto&auto=format&q=50&h=340&w=650`"-->
<!--            ></img-loading>-->
            <img :src="`${data.banner}?&dpr=1.25&format=auto&auto=format&q=50&h=340&w=650`" />
          </a>
        </div>
      </WSlider>
    </div>

    <div class="page-container">
      <WTabs
        :tabs="tabList"
        :activeTab="activeTab"
        @changeActive="changeActive"
      ></WTabs>
      <div v-for="(list, listIndex) in gameList"
          :key="listIndex"
          class="page-item"
      >
        <div class="header">
          <div class="title">{{getName(list.clazz.nameI18nJson)}}</div>
          <div class="nav">
            <div class="all" v-if="list.clazz.code !== 'all'" @click="toMoreGame(list.clazz)">{{ $t('wCasino.more') }}</div>
            <div class="left" @click="handleScroll(-300, listIndex)">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Arrow"></use>
              </svg>
            </div>
            <div class="right" @click="handleScroll(300, listIndex)">
              <svg viewBox="0 0 24 24" focusable="false">
                <use xlink:href="../assets/bcfun.svg#icon_Arrow"></use>
              </svg>
            </div>
          </div>
        </div>
        <div class="content" ref="content">
          <div v-for="(game, gameListIndex) in list.games"
               :key="gameListIndex"
               class="grid-item"
               @click="goGame(game)">
                  <img-loading
                      v-if="game.isLocal"
                      :loadingSrc="game.logo"
                      :src="game.logo"
                  ></img-loading>
                  <img-loading
                      v-else
                      :loadingSrc="`${imgUrl}${game.logo}?&dpr=1.25&format=auto&auto=format&q=50&blur=200&px=6&quality=15&h=220&w=187`"
                      :src="`${imgUrl}${game.logo}?&dpr=1.25&format=auto&auto=format&q=50&h=220&w=187`"
                  ></img-loading>
          </div>
        </div>
      </div>
    </div>

    <WModal v-show="isAntModal" @close="isAntModal = false" :noticesData="noticesData" />

    <BonusModal
        v-if="showBonusModal"
        :redId="redId"
        @setShowBonusModal="setShowBonusModal"></BonusModal>

    <div class="customer-service" @click.stop="showService = !showService">
      <span class="icon">
        <svg viewBox="0 0 24 24" focusable="false">
          <use xlink:href="../assets/icons.svg#icon-support"></use>
        </svg>
      </span>
      <div class="mask" v-show="showService" @click.stop="showService = !showService"></div>
      <div class="dropdown" v-show="showService">
        <div class="item"
             v-for="(data, index) in telegramList"
             @click="openServerLink(data.url)"
             :key="index">
          <img v-if="data.type === 'tg'" src="@/assets/images/icon-telegram.svg" />
          <img v-if="data.type === 'fb'" src="@/assets/images/icon-facebook.svg" />
          <img v-if="data.type === 'tt'" src="@/assets/images/icon-twitter.svg"/>
          <img v-if="data.type === 'wc'" src="@/assets/images/icon-whatsapp.svg"/>
          <img v-if="data.type === 'ins'" src="@/assets/images/ins-icon.png"/>
          <div class="desc">{{data.name}}</div>
          <img src="@/assets/images/icon-right.svg" />
        </div>
<!--        <div class="item">-->
<!--          <img src="@/assets/images/icon-whatsapp.svg" />-->
<!--          <div class="desc">Promoções e Notícias</div>-->
<!--          <img src="@/assets/images/icon-right.svg" />-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import WModal from '@/components/WModal.vue'
import WTabs from '@/components/WTabs.vue'
import WSlider from '@/components/WSlider.vue'
import BonusModal from '@/components/BonusModal.vue'
import cookies from "@/JS/util.cookies";
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'WCasino',
  components: {
    WModal,
    WTabs,
    WSlider,
    BonusModal,
  },
  data() {
    return {
      showBonusModal: false,
      showService: false,
      carouselIndex: 0,
      tabs: [
        { label: 'Lobby', value: 'Lobby' },
        { label: 'Popular', value: 'Popular' },
        { label: 'In-House', value: 'In-House' },
        { label: 'Slots', value: 'Slots' },
        { label: 'Live Casino', value: 'Live Casino' },
        { label: 'PG Slots', value: 'PG Slots' },
        { label: 'Fish', value: 'Fish' },
      ],
      activeTab: 'showId',
      data: [
        { name: 'Popular', image: '@/assets/images/11.png' },
        { name: 'In-House', image: '22.jpg' },
        { name: 'Slots', image: '11.png' },
      ],
      isAntModal: false,
      isMobile: window.innerWidth < 1028,
      swiperList: [],
      noticesData: {},
      redId: '',
      tabList: [
        {label: 'Lobby', value: 'showId'},
      ],
      gameList: [],
      cloneGameList: [],
      telegramList: [],
    }
  },
  computed: {
    ...mapState({
      imgUrl: state => state.imgUrl,
      partiesObj: state => state.partiesObj
    }),
  },
  created() {
    this.redId = this.util.getUrlParams('r');
    if (this.redId) this.showBonusModal = true;
    this.getClientMemberIndexBaseInfo();
  },
  methods: {
    ...mapMutations(['setGameSingle', 'setSiteInfo', 'setTelegramList', 'setSiteLogo']),
    setShowBonusModal(value) {
      this.showBonusModal = value
    },
    toActivityactivityNoticePage(data) {
      this.$router.push({
        path: '/promotions/detail',
        query: {
          activityCode: data.activityCode,
        }
      })
    },
    getName(data) {
      if (typeof data === 'string') {
        let lang = JSON.parse(data);
        return lang.en;
      } else{
        return data.en;
      }
    },
    async getClientMemberIndexBaseInfo() {
      try {
        let res = await this.$api.clientMemberIndexBaseInfo();
        if (res.success) {
          let data = res.result;
          let gameList = data.gamesInfo;
          this.$nextTick(() => {
            this.$set(this, 'swiperList', data.activity);
          })
          let notices = data.notices;
          if (gameList && gameList.length > 0) {
            for ( let i = 0; i < gameList.length; i++) {
              this.tabList.push({label: this.getName(gameList[i].clazz.nameI18nJson), value: gameList[i].clazz.id})
            }
            gameList.splice(1, 0, this.partiesObj);
            this.gameList = gameList;
            this.cloneGameList = gameList;
            // var arrEval = JSON.parse(res.result.site.instagram);
            // var arrParse = JSON.parse(res.result.site.instagram);
          }
          if (notices && notices.length > 0) {
            this.noticesData = notices[0];
            this.isAntModal = true;
          }
          let site = data.site;
          this.support = site.support;
          this.setSiteLogo(site.siteLogo);
          this.setSiteInfo(data);
          if (site.telegram) {
            this.telegramList = JSON.parse(site.telegram);
            this.setTelegramList(this.telegramList);
          }
        }
      } catch (e) {
        this.$message.error('Erro do sistema.Tente novamente mais tarde.');
      }
    },
    handleCarousel(delta) {
      let index = this.carouselIndex + delta
      index = Math.min(Math.max(0, index), 1)
      this.carouselIndex = index
    },
    changeActive(value, index) {
      this.activeTab = value
      this.$set(this, 'gameList', []);
      if (index === 0) {
        this.$nextTick(() => {
          this.$set(this, 'gameList', this.cloneGameList);
        })
      } else {
        let newIndex = this.cloneGameList.findIndex(data => { return data.clazz.id === value });
        let arr = [];
        arr.push(this.cloneGameList[newIndex]);
        arr.push(this.partiesObj);
        this.$nextTick(() => {
          this.$set(this, 'gameList', arr);
        })
      }
    },
    handleScroll(delta, index) {
      this.$refs.content[index].scrollLeft += delta
    },
    goGame(data) {
      if (data.isLocal) {
        this.$router.push({
          path: '/casino/group',
          query: {
            gameName: this.getName(data.nameI18nJson),
            venueId: data.codeId,
            folders: data.vaneCode
          }
        })
      } else{
        this.setGameSingle(data);
        this.$router.push({
          path: '/casino/game',
          query: {
            gameId: data.lottery_id
          }
        })
      }
    },
    toMoreGame(data) {
      this.$router.push({
        path: `/casino/group`,
        query: {
          gameName: this.getName(data.nameI18nJson),
          folders: data.code,
        }
      })
    },
    openServerLink(link) {
      const { isMobile } = this;
      if (isMobile) {
        window.location.href = link;
      } else {
        window.open(link);
      }
      if (isMobile) this.close();
    },
    handleResize() {
      this.isMobile = window.innerWidth < 1028
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1028px) {
}

@media screen and (max-width: 1028px) {
  .page {
    background: transparent !important;
    padding: 76px 0 0 0 !important;
  }
  .carousel {
    display: none;
  }
  .content {
    gap: 8px !important;
  }
  .grid-item {
    max-width: calc(33.33333vw - 16px) !important;
    min-width: calc(33.33333vw - 16px) !important;
  }

  .pc {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}

.pc {
  display: block;
}
.mobile {
  display: none;
}
.page {
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
  padding: 32px;
  background: var(--theme-1800);
}
.carousel {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 8px;
  position: relative;
  .swiper {
    width: 100%;
    overflow: hidden;
    position: relative;
    .container {
      display: flex;
      align-items: center;
      transform: translate3d(0%, 0px, 0px);
      transition-duration: 200ms;
      transition-property: transform;
    }
    img {
      flex: none;
      width: 24.5%;
      margin-right: 0.666%;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
    }
  }
  .left {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9;
    margin-top: -1.125rem;
    top: 42%;
    left: -1.25rem;
    border-radius: 2px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    background: var(--theme-2800);
    transform: translateY(-40%);
    fill: var(--theme-1100);
    transform: rotate(180deg);
    &:hover {
      background: #2d3035;
    }
    svg {
      width: 12px;
    }
  }
  .right {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9;
    margin-top: -1.125rem;
    top: 50%;
    right: -1.25rem;
    border-radius: 2px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    background: var(--theme-2800);
    transform: translateY(-40%);
    fill: var(--theme-1100);
    &:hover {
      background: #2d3035;
    }
    svg {
      width: 12px;
    }
  }
  .dots {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dot {
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      margin: 0 0.375rem;
      overflow: hidden;
      background: hsla(0, 0%, 59.2%, 0.3);
    }
    .active {
      background-color: #fff;
    }
  }
}

.slider-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    border-radius: 8px;
  }
}
.page-container {
  padding-top: 1.5rem;
  position: relative;
  width: 100%;
  .page-item {
    margin-top: 1.7rem;
    .header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .title {
      font-size: 1.25rem;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      user-select: none;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
      text-transform: capitalize;
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -0.25rem;
      right: 0;
      z-index: 2;
      .all {
        color: var(--theme-green);
        display: block;
        font-size: 14px;
        font-weight: 700;
        padding: 0 0.625rem;
        height: 2.25rem;
        line-height: 2.25rem;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }
      .left {
        margin-right: 1px;
        width: 2.25rem;
        height: 2.25rem;
        background-color: var(--theme-600);
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);
        fill: var(--theme-1100);
        transform: rotate(180deg);
        &:hover {
          background: #2d3035;
        }
        svg {
          width: 12px;
        }
      }
      .right {
        width: 2.25rem;
        height: 2.25rem;
        background-color: var(--theme-600);
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);
        fill: var(--theme-1100);
        &:hover {
          background: #2d3035;
        }
        svg {
          width: 12px;
        }
      }
    }
    .content {
      gap: 16px;
      display: flex;
      overflow-y: scroll;
      width: 100%;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display: none;
      }
      .grid-item {
        max-width: 164px;
        min-width: 164px;
        cursor: pointer;
        user-select: none;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
        }
      }
    }
  }
}
.customer-service {
  width: 44px;
  height: 44px;
  z-index: 10;
  position: fixed;
  bottom: 4rem;
  right: 10px;
  background: var(--green-500);
  position: fixed;
  color: #fff !important;
  user-select: none !important;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    z-index: 5888;
  }
  .icon svg {
    width: 24px;
    height: 24px;
    color: #fff;
    fill: currentColor;
  }
  .dropdown {
    position: absolute;
    bottom: 0px;
    right: 44px;
    background: var(--theme-200);
    box-shadow: none;
    border-radius: 12px;
    color: white;
    visibility: visible;
    max-width: 95vw;
    min-width: 250px;
    max-height: 65vh;
    z-index: 6000;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    overflow: hidden;
    .item {
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      img:nth-child(1) {
        width: 24px;
        height: 24px;
      }
      .desc {
        flex: 1;
        margin-left: 8px;
      }
      img:last-child {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
