<template>
  <button class="button">
    <slot></slot>
  </button>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.button {
  background: var(--theme-green);
  text-transform: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  padding: 0 16px;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  max-width: 100%;
  outline: 0 !important;
  user-select: none !important;
  color: #fff !important;
  cursor: pointer;
  user-select: none;
  // flex-direction: column;
  border: 0;
  vertical-align: middle;
  text-align: center;
  width: auto;
  &:hover {
    opacity: 0.9;
  }
}

@media screen and (max-width: 1028px) {
  .button {
    font-size: 12px !important;
    height: 32px !important;
    padding: 0 12px;
  }
}
</style>
