import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import api from '@/api'
import utils from '@/JS/utils'
// import fastclick from 'fastclick'
import animated from 'animate.css'
// fastclick.attach(document.body)
import Quasar from 'quasar'
import 'quasar/dist/quasar.min.css'

// 注册全局组件
import '@/components'
import Loading from '@/components/loading'

// 引入语言包，注意路径
import Brazil from '@/JS/locales/brl.js';
import English from '@/JS/locales/en.js';
import MexIco from '@/JS/locales/mexico.js';

// VueI18n
import VueI18n from '@/JS/vue-i18n.min.js';

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$loading = Loading

Vue.use(utils)
Vue.use(Antd)
// Vue.use(animated)
Vue.use(VueI18n)
Vue.use(Quasar)

const i18n = new VueI18n({
  // 默认语言
  locale: 'brl',
  // locale: 'mxg',
  // 引入语言文件
  messages: {
    'brl': Brazil,
    'en': English,
    'mxg': MexIco
  }
});

export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
