export default {
  currency: 'MXN',
  zone: '52',
  interface: {
    '2005': 'Error CAPTCHA', // 验证码错误
    '2004': 'Captcha no válido',// 验证码无效
    '2008': 'El inicio de sesión ha fallado demasiadas veces, inténtelo de nuevo más tarde', // 登录失败次数过多，请稍后再试
    '2003': 'El usuario está deshabilitado, póngase en contacto con el servicio de atención al cliente',// 用户被禁用，请联系客服
    '2002': 'Registro fallido, nombre de usuario duplicado', // 注册失败，用户名称重复
    '2001': 'Nome de usuário ou senha incorretos', // 用户名或密码错误
    '500': 'Error del servidor, póngase en contacto con el administrador', // 服务器错误，请联系管理员
    '401': 'El token no es válido, por favor inicie sesión de nuevo', // token失效，请重新登录
    "4010": 'Error de tarjeta bancaria', // 银行卡错误
    "4011": 'Incumplimiento del importe mínimo de retirada única', // 不符合单笔最小提取金额
    "4012": 'Incumplimiento del importe máximo de retirada única', // 不符合单笔最大提取金额
    "4013": 'No alcanza el importe máximo total de retirada para el día', // 不符合当日最大提取总金额
    "4014": 'No alcanza el número máximo total de retiradas del día', // 不符合当日最大提取总次数
    "4015": 'Apuestas fallidas', // 打码量未达到标准
    "4016": 'Equilibrio insuficiente.', // 余额不足
    "4020": 'Compruebe que el número de tarjeta y el nombre de la cuenta son correctos antes de volver a enviar la solicitud.', // 请检查银行卡号和账户名是否正确再重新提交
    "4022": 'El pedido está siendo revisado, por favor inténtelo más tarde.', // 还有审核中提现订单，请稍后再试
    "6000": 'Para retiradas frecuentes, espere 10 segundos y vuelva a intentarlo.', //
    "4017": 'No se puede retirar efectivo sin una recarga correcta', // 未充值成功过不能够提现
    "4018": 'No permitir que diferentes usuarios añadan la misma tarjeta bancaria', // 不允许不同用户添加相同银行卡
    "4019": 'Cambio de nombre de los beneficiarios de tarjetas bancarias', // 银行卡收款姓名的重名
    "4009": 'Hay demasiadas tarjetas bancarias para seguir añadiendo, póngase en contacto con el servicio de atención al cliente.', // 银行卡数量过多，不能继续添加，请联系客服
  },
  login: {
    userName: 'Número de Teléfono',
    password: 'Contraseña',
    captcha: 'Código de Verificación',
    reset: 'Esqueci minha senha',
    btn: 'Iniciar Sesión'
  },
  registrar: {
    userName: 'Número de Teléfono',
    password: 'Contraseña',
    captcha: 'Código de Verificación',
    inviteCode: 'Código de Invitación',
    btn: 'Inscribirse'
  },
  red: {
    "-1": 'Se acabaron las bonificaciones.', // 红包领取完毕
    "-2": 'No cumple los criterios establecidos en el Paquete Rojo y no puede ser recibido por el usuario actual.', // 不符合红包设定的规则， 当前用户不能领取
    "2": 'Cada persona sólo puede cobrar una vez',
    "0": 'Éxito en la recogida de premios'
  },
  wCasino: {
    more: 'más'
  },
  convidar: {
    head: 'Plan Recomendado',
    tabs: {
      tab1: 'Invitar',
      tab2: 'Estadísticas',
      tab3: 'Formularios',
    },
    share: {
      title: 'INVITAR A UN SOCIO',
      subTitle: 'URL de Invitación:',
      subTitle1: 'Compartir Vía:'
    },
    bouns: {
      inviteUsers: 'Invitar Usuarios',
      depositUsers: 'Depositar Usuarios',
      todayBonus: 'Bono de Hoy',
      yesterDayBonus: 'Bono de Ayer'
    },
    some: {
      title: 'Metas de Ingresos Mensuales',
      fl: 'MXN 0',
      desc: '5 más gente a invitar antes de que se alcance la meta'
    },
    convite: {
      tit: 'CÓMO FUNCIONA EL BONO DE INVITACIONES?',
      subTit: 'Bono de Invitación',
      desc: 'Cada usuario depositante tiene derecho a un mínimo de',
      desc1: 'invitaciones a la vez. Cuanto mayor sea el importe del depósito, mayor será la bonificación por invitación para el nivel correspondiente.',
      people: 'Depósito',
      amount: 'Bono'
    },
    funciona: {
      tit: 'CÓMO FUNCIONA LA BONIFICACIÓN POR INVITACIÓN?',
      desc: 'Este será su ingreso a largo plazo y recibirá un porcentaje diferente de comisión cada vez que un jugador que invite haga una apuesta.',
      desc1: 'Reglas de Bonos：Todos los jugadores en el nivel "Promotor" reciben un porcentaje del bono de borde de plataforma por cada apuesta.',
      desc2: 'Las proporciones de los 3 niveles son las siguientes：',
      leavel1Lef: '·Nivel 1：Conseguir ',
      leavel1Rig: 'ventaja de plataforma',
      leavel2Lef: '·Nivel 2：Conseguir',
      leavel2Rig: 'ventaja de plataforma',
      leavel3Lef: '·Nivel 3：Conseguir',
      leavel3Rig: 'ventaja de plataforma',
    },
    renda: {
      tit: 'CALCULADORA DE INGRESOS',
      amount: 'MXN 0',
      desc: 'CALCULADORA DE INGRESOS 100 usuarios activos Esperan que se obtengan ingresos anuales'
    },
    leader: {
      tit: 'Marcador',
      amount: 'MXN',
      top: 'TOP',
      username: '+52'
    },
    records: {
      tit: 'QUIÉN GANÓ EL PREMIO',
      currency: 'MXN',
      desc: 'Reciba un bono de invitación',
      zone: '+52'
    }
  },
  statistics: {
    tab: {
      tabTodos: 'Todo',
      tabHoje: 'Hoy',
      tabOntems: 'Ayer',
      tabAll: 'La semana pasada'
    },
    nivel0: 'Todas las estadísticas',
    nivel1: 'Nivel 1',
    nivel2: 'Nível 2',
    nivel3: 'Nível 3',
    invitationNumber: 'Invitado',
    validBet: 'Apuestas Válidas',
    depositAmount: 'Cantidad de Depósito',
    commission: 'Comisión',
    inviteRechargeUserNum: 'Depositar Usuarios'
  },
  forms: {
    selectGift: 'Reciba un bono de invitación',
    selectComos: 'Reciba un bono de comisión',
    tableTempo: 'Hora',
    tableUser: 'Usuarios',
    tableAmount: 'Bono'
  },
  column: {
    casino: 'Casino', // 首页大厅
    convidar: 'Referencia', // 邀请模块
    gift: 'Promociones', // 活动模块
    bouns: 'Bouns', //
    wallet: 'Depósito', // 充值模块
    vip: 'VIP', // VIP模块
    caseBack: 'Cash Back',
    tarn: 'Transacción',
    note: 'Notificaciones',
    setting: 'Configuración',
    sever: 'Soporte en vivo',
    favourites: 'Favoritos',
    recent: 'Reciente',
    home: 'Inicio',
    inHouse: 'In-House',
    slots: 'Slots',
    liveCasino: 'Live Casino',
    pGSlots: 'PG Slots',
    fish: 'Fish'
  },
  logout: 'Cerrar Sesión',
  logOutComp: {
    desc: '¡No olvide consultar nuestra enorme selección de promociones deportivas y de casino antes de irse!'
  },
  signUp: 'Sign Up',
  winNav: {
    navigation: 'Navigation',
    casino: 'Casino'
  },
  winFooter: {
    support: 'Support',
    top: 'Top'
  },
  vipModal: {
    progTit: 'Seu progresso VIP',
    vip: 'VIP',
    tit: 'Mais benefícios Vip',
    desc: 'Seu progresso é uma soma acumulada através de sua aposta tanto em nosso cassino quanto em nossa cartilha esportiva. Aumentar através dos níveis lhe dá direito a maiores recompensas e tratamento VIP exclusivo',
    notice: 'Todas as apostas liquidadas progredirão mais rapidamente. Não estão incluídas as apostas que se liquidam como nulas',
    benefs: 'Benefícios',
    maxWithdraw: 'Valor máximo de saque em uma única transação',
    maxDayWithdrawCount: 'Retiradas diárias Count',
    upgradeGift: 'bolsa de promoção',
    recharge: 'Montante de recarga necessário',
    minWithdraw: 'Valor mínimo de saque único'
  },
  bottomNav: {
    menu: 'Menú',
    bonus: 'Bonos',
    deposit: 'Deposit',
    invite: 'Invitar',
    casino: 'Casino'
  },
  bonusModal: {
    tit: 'Congratulations Get',
    subTit: 'Um Grande Gratuito Bônus!',
    btn: 'Get Now'
  },
  addCard: {
    tit: 'Adicionar conta bancária',
    cardTypeLabel: 'Tipo de conta',
    cardType: 'Seleccione el tipo de tarjeta',
    customerName: 'por favor, digite seu nome verdadeiro',
    customerNameLabel: 'Nome',
    cardNoLabel: 'Número De Tarjeta',
    btn: 'Submeter',
    cardNoTips: 'El número de tarjeta no debe estar vacío',
    cardUserTips: 'El nombre del titular de la tarjeta no debe estar en blanco',
    cardTypeTips: 'El tipo de tarjeta bancaria no puede estar vacío',
    addCardTips: 'Compruebe cuidadosamente sus datos bancarios, de lo contrario no podrá retirar el dinero correctamente.'
  },
  winWallet: {
    tit: 'Mi Cartera',
    tab1: 'Depósito',
    tab2: 'Retirar',
    tab3: 'Centro de Cartera',
    tab4: 'Premio'
  },
  winDep: {
    tit: 'Canal de Depósito',
    subTit: 'Cantidad de Depósito',
    depDesc: 'Importe mínimo del depósito:',
    titBonus: 'Depósito de Bono',
    titApo: 'Requerir Apuesta',
    titCheck: 'No participar en actividades objetivo',
    btn: 'Depósito',
    reBack: 'Volver a Recarga',
    btnBack: 'Volver ',
    orderDesc: 'El pedido ha sido creado con éxito, ¡por favor haga clic en el botón de abajo para pagar!',
    payBtn: 'Ir a Pago',
    depList: 'Registro de depósito',
    minDepTips: 'Importe mínimo del depósito:'
  },
  winCentralComp: {
    userBalance: 'Afirmar Total',
    betBalance: 'Requerir Apuesta',
    cumulativeCommission: 'Commisión',
    codeAmount: 'sem limite'
  },
  winPre: {
    table: {
      name: 'Nombre',
      quantia: 'Cuenta',
      status: 'Estado',
      tempo: 'Hora'
    },
    vip_upgrade: 'Recompensa de ascenso VIP',
    recharge_gift: 'Recarga Bonificación',
    first_recharge_gift: 'Primera Recarga Bonificación',
    invite_gift: 'Invitaciones Conceder Dinero',
    commission1: 'Nivel 1 Commisión',
    commission2: 'Nivel 2 Commisión',
    commission3: 'Nivel 3 Commisión',
    giveStatus1: 'Recibido',
    giveStatus2: 'No recibido'
  },
  winRetirar: {
    userBalanceDesc: 'Por favor, haga un depósito antes de intentar retirar.',
    userBalanceTit: 'Saldo disponible',
    retirarDesc: 'Importe de la retirada',
    subTit: 'Retiradas de Efectivo',
    addCardTit: 'Añadir cuenta bancaria',
    checkTit: 'Consentimiento de uso',
    minWithdraw: 'Importe mínimo de retirada :',
    maxWithdraw: 'Importe máximo de retirada :',
    commissionAmount: 'Tasa',
    finallyAmount: '(Recibirá:',
    codeAmountLeft: 'Demanda adicional(',
    codeAmountRig: ') Deducción de apuestas',
    btn: 'Envíe un mensaje a',
    columns: {
      name: 'Sedes Excluidos',
      number: 'Requerir Apuesta'
    },
    registroTit: 'Registros de retiradas'
  },
  trade: {
    tit: 'Transacciones',
    tab1: 'Depósitos',
    tab2: 'Retiros',
    tab3: 'Apuestas',
    tab4: 'Detalle de Saldo',
  },
  winApost: {
    columns: {
      name: 'Game',
      bet: 'Betting Amount',
      payout: 'Pay',
      time: 'Time',
    },
    tit: 'Supplier Betting'
  },
  winDepos: {
    desc: 'No record.',
    status0: 'Waiting for recharge',
    status1: 'successes',
    status2: 'abortive',
  },
  winDetaIhe: {
    tit: 'Filter by',
    desc: 'No record.',
    invite_gift: 'Invitations Grant Money',
    recharge_gift: 'Recharge Bonus',
    first_recharge_gift: 'First Recharge Bonus',
    orderBh: 'Failed withdrawal request',
    chongZhi: 'recharge',
    vipSj: 'VIP Upgrade Reward',
    yongJin: 'commissions',
    huiYTx: 'Member Withdraw Money',
    shouUp: 'Systematic increase in balances',
    shouDown: 'Systematic reduction of balances'
  },
  traWithdraw: {
    desc: 'No record.',
    status20: 'Ongoing withdrawal request',
    status10: 'Ongoing withdrawal request',
    status21: 'Ongoing withdrawal request',
    status01: 'Successful withdrawal',
    status02: 'Not available, please check your card details',
    status403: 'Not available, please check your card details',
  },
  favorites: {
    tit: 'Favourites',
    empty: 'Click to collect your favourite games and find them here easily',
  },
  recente: {
    tit: 'Recent',
    empty: 'There is no record of any recent work.',
  },
  prevBtn: 'Previous',
  nextBtn: 'Next',
}
