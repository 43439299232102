// 为DOM元素添加类名
export function addClass (el, className) {
    if (hasClass(el, className)) {
      return
    }
    let newClass = el.className.split(' ')
    newClass.push(className)
    el.className = newClass.join(' ')
  }
  
  // 判断DOM元素是否拥有此类名
  export function hasClass (el, className) {
    let reg = new RegExp('(^|\\s)' + className + '(\\s|$)')
    return reg.test(el.className)
  }
  
  // 获取元素 'data-' + name 开头的属性值
  export function getData (el, name, val) {
    const prefix = 'data-'
    name = prefix + name
    if (val) {
      return el.setAttribute(name, val)
    } else {
      return el.getAttribute(name)
    }
  }
  
  // 为 css 属性添加浏览器前缀
  let elementStyle = document.createElement('div').style
  
  let vendor = (() => {
    let transformNames = {
      standard: 'transform',
      webkit: 'webkitTransform',
      Moz: 'MozTransform',
      O: 'OTransform',
      ms: 'msTransform'
    }
  
    // 能力检测
    for (let key in transformNames) {
      if (elementStyle[transformNames[key]] !== undefined) {
        return key
      }
    }
  
    return false
  })()
  
  export function prefixStyle (style) {
    if (vendor === false) {
      return false
    }
  
    if (vendor === 'standard') {
      return style
    }
  
    return vendor + style.charAt(0).toUpperCase() + style.substr(1)
  }
  