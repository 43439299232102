import Cookies from 'js-cookie'

const cookies = {};

/**
 * @description 存储 cookie 值
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function (name = '', cookieSetting = {}) {
  // Cookies.set(name, cookieSetting)
  let data;
  if (typeof cookieSetting === 'object') {
    data = JSON.stringify(cookieSetting);
  } else {
    data = cookieSetting
  }
  localStorage.setItem(name, data)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = '') {
  // return Cookies.get(`${name}`)
  return localStorage.getItem(name);
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
  return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = '') {
  // return Cookies.remove(`${name}`)
  return localStorage.removeItem(name);
}

export default cookies
